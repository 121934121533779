import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor() {}

  getinfoSwitcherUrl(): string {
    return environment.loginURL + '/utilisateurs/switcher/';
  }

  public isOAuthEnabled(): boolean {
    return environment.oAuthEnable;
  }

  public isProduction(): boolean {
    return environment.production;
  }

  public baseApiPath(): string {
    return environment.apiURL;
  }
  public getLoginUrl(): string {
    return environment.loginURL;
  }
  public getloginSwitcherUrl(): string {
    return environment.loginURL + '/switchon/direct';
  }
  public getloginSwitcherUrlOff(): string {
    return environment.loginURL + '/switchoff';
  }

  public getenvironment(): string {
    return environment.environment;
  }
}
