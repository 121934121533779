import { NgModule } from '@angular/core';
import { TemplateComponent } from './template.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [RouterModule],
  declarations: [TemplateComponent],
  exports: [TemplateComponent],
})
export class TemplateModule {
  userId: string;
}
