import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { AuthenticationService } from 'app/shared/services/authentication.service';
import { AuthorizationService } from 'app/shared/authorization/authorization.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
})
export class LogoutComponent implements OnInit {
  windowWidth: string;
  constructor(private _fuseConfigService: FuseConfigService, private router: Router, private authorizationService: AuthorizationService) {
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }

  ngOnInit(): void {
    this.authorizationService.logout();
  }

  redirectToLogin(): void {
    this.router.navigate(['']);
  }
}
