<div class="app-splash-screen" [ngStyle]="{ left: windowWidth }" *ngIf="showSplash">
  <div class="app-splash-inner">
    <div class="logo">
      <!-- <img width="128" src="assets/images/logos/logo-small-200.png" /> -->
      <img width="400" src="assets/images/logos/logo-RN-JADE-DA-bl.svg" />
    </div>
    <div class="app-label">Connexion réussie...</div>
    <div class="spinner-wrapper">
      <div class="spinner">
        <div class="inner">
          <div class="gap"></div>
          <div class="left">
            <div class="half-circle"></div>
          </div>
          <div class="right">
            <div class="half-circle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="loading$ | async">
  <div class="overlay"></div>
  <mat-progress-spinner [mode]="'indeterminate'" color="accent"></mat-progress-spinner>
</ng-container>

<fuse-progress-bar></fuse-progress-bar>
<ng-container>
  <vertical-layout-1></vertical-layout-1>
</ng-container>
