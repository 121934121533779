import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';

import { ProfileService } from 'app/main/pages/profile/profile.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserInfo } from 'angular-oauth2-oidc';
import { AuthorizationService } from 'app/shared/authorization/authorization.service';
import { UtilisateurService } from 'app/shared/services/utilisateur.service';
import { IUtilisateur, Utilisateur } from 'app/shared/model/utilisateur.model';

@Component({
  selector: 'profile-photos-videos',
  templateUrl: './photos-videos.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,

  styleUrls: ['./photos-videos.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ProfilePhotosVideosComponent implements OnInit {
  /**
     * Constructor
     *
     
     */
  about: any;
  currentUser: UserInfo;
  ipn: string;
  data: IUtilisateur;
  idUser: any;
  adressePostal: any;
  email: boolean;
  ref_portable: any;
  ref_telephone: any;
  ref_adresse: any;
  courrier: boolean;
  sms: boolean;
  dataProfil: any;
  dataGroupeDto: any;
  message: any;
  defaultAffaire: any;
  ref_mail: any;
  isswitcheduser: boolean = true;
  /**
     * Constructor
     *

     */
  constructor(private authorizationService: AuthorizationService, private apiService: UtilisateurService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.authorizationService.currentUser.subscribe(x => {
      this.currentUser = x;
      this.ipn = this.currentUser.uid;
      this.idUser = this.currentUser.id;
    });
    this.authorizationService.isswitcheduserflag.subscribe(x => {
      this.isswitcheduser = x;
      this.getProfil();
    });
  }

  updateUser() {
    this.data = {
      id: this.data.id,
      ipn: this.data.ipn,
      ref_mail: this.data.ref_mail,
      ref_portable: this.data.ref_portable,
      ref_telephone: this.data.ref_telephone,
      ref_adresse: this.data.ref_adresse,
      sms: this.data.sms,
      nom: this.data.nom,
      prenom: this.data.prenom,
      email: this.data.email,
      courrier: this.data.courrier,
      groupe: this.dataGroupeDto,
      drs: this.data.drs,
      notification: this.data.notification,
      derniere_connexion: this.data.derniere_connexion,
    };

    this.apiService.update(this.data).subscribe(result => {});
  }

  getProfil() {
    this.apiService.createUserProfil().subscribe((res: any) => {
      this.data = res.body.utilisateurDto;
      this.dataGroupeDto = res.body.groupeDto;
      this.email = res.body.utilisateurDto.email;
      this.sms = res.body.utilisateurDto.sms;
      this.courrier = res.body.utilisateurDto.courrier;
      this.ref_telephone = res.body.utilisateurDto.ref_telephone;
      this.ref_adresse = res.body.utilisateurDto.ref_adresse;
      this.dataGroupeDto = res.body.groupeDto;
    });
  }
}
