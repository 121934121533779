import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: fuseAnimations,
})
export class HeaderComponent implements OnInit {
  url: string;
  pageTitle = 'Jade';
  searchTitle = 'Recherche';
  icon = 'business';
  isSearch = false;
  @Input() hidebt: boolean;
  @Input() btexport: boolean;
  @Input() title: string;


  @Output() eventSearch: EventEmitter<any> = new EventEmitter<string>();
  @Output() eventNew: EventEmitter<any> = new EventEmitter<string>();
  @Output() eventExport: EventEmitter<any> = new EventEmitter<string>();

  searchInput: any;
  @Input() modal: boolean;
  @Input() issearchactivated: boolean;
  constructor(private titleService: Title, private router: Router) {
    this.searchInput = new FormControl('');
  }

  ngOnInit(): void {
    this.url = this.router.url;
    this.init();
    this.searchInput.valueChanges.pipe(debounceTime(900), distinctUntilChanged()).subscribe(event => {
      this.onSearch(event);
    });
  }

  onSearch(value: string): void {
    this.eventSearch.emit(value);
  }

  onNew(): void {
    this.eventNew.emit();
  }

  onExport(): void {
    this.eventExport.emit();
  }

  private getData(routeSnapshot: ActivatedRouteSnapshot, data: string): string {
    let info: string = routeSnapshot.data && routeSnapshot.data[data] ? routeSnapshot.data[data] : '';
    if (routeSnapshot.firstChild) {
      info = this.getData(routeSnapshot.firstChild, data) || info;
    }
    return info;
  }

  private init(): void {
    const pageTitle = this.getData(this.router.routerState.snapshot.root, 'pageTitle');
    const searchTitle = this.getData(this.router.routerState.snapshot.root, 'searchTitle');
    const icon = this.getData(this.router.routerState.snapshot.root, 'icon');

    if (this.title !== undefined) {
      this.pageTitle = this.title;
    } else {
      if (pageTitle) {
        this.pageTitle = pageTitle;
      }
    }

    if (searchTitle) {
      this.searchTitle = searchTitle;
      if (this.issearchactivated == false) {
        this.isSearch = false;
      } else {
        this.isSearch = true;

      }
    }

    if (icon) {
      this.icon = icon;
    }

    this.titleService.setTitle(this.pageTitle.replace(/<[^>]*>/g, ''));
  }
}
