import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { GestionMaintenancesService } from 'app/shared/services/gestion-maintenanes.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthorizationService } from '../authorization.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardMaintenanceService implements CanActivate {

  constructor(private router: Router, private gestionMaintenancesService: GestionMaintenancesService, private authorizationService: AuthorizationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.gestionMaintenancesService.getParametresGeneraux().pipe(
      map(parametresGenerauxResponse => {
        if (parametresGenerauxResponse.body) {
          if (parametresGenerauxResponse.body.maintenance) {

            const currentUser = this.authorizationService.infocurrentUser.getValue();

            const allowedProfiles = route.data.allowedProfiles as string[];

            if (allowedProfiles && allowedProfiles.includes(currentUser.profilDto.code)) {
              return true;
            }

            this.router.navigate(['page-maintenance']);
            return false;
          } else {
            return true;
          }
        }

        return true;
      }),
      catchError(() => {
        this.router.navigate(['pages/errors/error-404']);
        return of(false);
      })
    );
  }
}