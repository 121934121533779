<mat-toolbar>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>
    <div class="accent h5 inline pl-20 pr-20">
      <mat-icon class="s-16 mr-sm-4">access_time</mat-icon>
      <span
        >Dernière date de connexion : <b>{{ latest_date }}</b></span
      >
    </div>


 

    <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
      <div class="text-right mentionlegal" fxFlex="60">Mentions légales &nbsp;:
        <a mat-button href="https://www.renault.fr/donnees-personnelles.html" target="_blank">Renault</a> / 
        <a mat-button href="https://www.dacia.fr/donnees-personnelles.html" target="_blank">Dacia</a>
      </div>
      <div class="text-right" fxFlex="40">
        <a mat-button href="https://www.renault.fr/" target="_blank"> © Renault 2022 - 2023</a>
      </div>
    </div>
  </div>
</mat-toolbar>
