<div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
  <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="logo-icon s-32 mr-16" [@animate]="{ value: '*', params: { delay: '50ms', scale: '0.2' } }">
      {{ icon }}
    </mat-icon>
    <span class="logo-text h1" [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }">
      {{ pageTitle }}
    </span>
  </div>
  <div class="search-wrapper mx-32 mx-md-0" *ngIf="isSearch">
    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
      <mat-icon>search</mat-icon>
      <input type="text" [formControl]="searchInput" placeholder="{{ searchTitle }}" />
    </div>
  </div>
  <!--  *ngIf="!modal" -->
  <button *ngIf="!hidebt" mat-raised-button [routerLink]="[url, 'add']" class="fuse-white mt-24 mt-md-0">
    <span>Ajouter</span>
  </button>
  <!-- *ngIf="modal" -->
  <!-- <button *ngIf="modal"  mat-raised-button (click)="onNew()" class="fuse-white mt-24 mt-md-0">
        <span>Ajouter</span>
    </button> -->

    <button *ngIf="btexport" mat-raised-button (click)="onExport()" class="fuse-white mt-24 mt-md-0">
      <span>Exporter</span>
    </button>
</div>
