import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { HeaderService } from 'app/shared/services/header.service';
import { Observable } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { Location } from '@angular/common';
import { CibleCanal, ICibleCanal } from 'app/shared/model/cible-canal.model';

@Component({
  selector: 'headerformciblage',
  templateUrl: './headerformciblage.component.html',
  styleUrls: ['./headerformciblage.component.scss'],
  animations: fuseAnimations,
})
export class HeaderFormCiblageComponent implements OnInit {
  url: string;
  pageTitle = 'Jade';
  icon = 'business';
  buttontype = 'Valider';
  @Input() hidebt: boolean;
  @Input() title: string;
  @Input() disable: boolean;
  @Input() groupeCode: string;
  @Input() ciblecanals: ICibleCanal[];

  @Output() save: EventEmitter<any> = new EventEmitter();
  pageText: Observable<string>;

  constructor(
    private titleService: Title,
    private router: Router,
    private headerService: HeaderService,
    protected activatedRoute: ActivatedRoute,
    private _location: Location
  ) {
    if (this.activatedRoute.routeConfig.path === 'add') {
      this.buttontype = 'Valider';
    } else {
      if (this.activatedRoute.routeConfig.path === ':id/view') {
        this.buttontype = '';
      }
    }
  }

  ngOnInit(): void {
    this.headerService.setText('');
    this.url = this.router.url;
    this.init();
  }

  saveForm(): void {
    this.save.emit();
  }

  cancel(): void {
    if (this.url && this.url === "/apps/routage/add") {
      this.router.navigate(['/apps/routage']);
    }
    let locationState: any;
    locationState = this._location.getState();
    if (locationState.navigationId && locationState.navigationId > 1) {
      this._location.back();
    } else {
      if (this.groupeCode === 'RZO') {
        this.router.navigate(['/apps/cible/mescibles']);
      } else {
        this.router.navigate(['/apps/cible/']);
      }
    }
  }

  private getData(routeSnapshot: ActivatedRouteSnapshot, data: string): string {
    let info: string = routeSnapshot.data && routeSnapshot.data[data] ? routeSnapshot.data[data] : '';
    if (routeSnapshot.firstChild) {
      info = this.getData(routeSnapshot.firstChild, data) || info;
    }
    return info;
  }

  private init(): void {
    const pageTitle = this.getData(this.router.routerState.snapshot.root, 'pageTitle');
    const icon = this.getData(this.router.routerState.snapshot.root, 'icon');

    if (this.title !== undefined) {
      this.pageTitle = this.title;
    } else {
      if (pageTitle) {
        this.pageTitle = pageTitle;
      }
    }

    if (icon) {
      this.icon = icon;
    }

    this.titleService.setTitle(this.pageTitle.replace(/<[^>]*>/g, ''));

    this.pageText = this.headerService.getText();
  }

  sortCanal(canals: CibleCanal[]): CibleCanal[] {
    let sortedCanals = [];
    if (canals != null) {
      canals = canals.filter((canal, index, self) =>
        index === self.findIndex(c => c.canal.code === canal.canal.code)
      );
      sortedCanals = [...canals].sort((a, b) => a.canal.libelle.localeCompare(b.canal.libelle));
    }
    return sortedCanals;
  }

}
