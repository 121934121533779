<div class="page-layout carded fullwidth inner-scroll">
  <div class="center">
    <div style="text-align: center;"><br />Bienvenue sur votre nouvel outil JADE.</div>
    <h1><mat-icon>shopping_cart</mat-icon> Votre tableau de bord</h1>
    <div>
      <div style="display:flex">
        <div *ngIf="selectedAffaires.length >0">
          Sélectionner une affaire:
          <mat-form-field appearance="fill">
            <mat-select [(value)]="selected" (selectionChange)="onSelectAffaire($event)">
              <input type="text" class="form-control" placeholder="Rechercher une affaire" [(ngModel)]="searchInput"
                (keyup)="searchAffaire()" />
              <mat-option *ngFor="let affaire of selectedAffaires" [value]="affaire.code">
                <b> {{ affaire.code }} - {{ affaire.libelle }}</b>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>



      </div>
    </div>
    <div>
      <Strong><b><u>Notifications importantes ce mois:</u></b></Strong>
      <div *ngFor="let bandeau of bandeauList">
        <mat-card style="margin:6px; background-color: #ffc107;">
          <mat-card-content> <strong> {{bandeau.message}} </strong> </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="m-20">
      Vous trouverez ci-dessous le détail de votre « terrain de jeu » par famille de contacts selon l'affaire
      sélectionnée.<br>
      Un contact ne peut être rattaché qu'à une seule famille de contacts selon la priorisation suivante : 1: clients
      juridiques, 2: prospects, 3: prospects AAA.<br>
      <b><u>Attention</u></b>, vos ciblages Jade sont calculés uniquement sur la base des contactables à savoir les
      contacts ayant au minimum un moyen de contact valide avec un accord de communication. Le volume de vos contacts «
      base brute » est communiqué à titre indicatif.
    </div>

    <div class="m-20" *ngIf="volumes!==null">
      <table class="mat-table">
        <tr class="mat-header-row">
          <td class="mat-header-cell"></td>
          <td class="mat-header-cell">Vos clients juridiques</td>
          <td class="mat-header-cell">Vos prospects</td>
          <td class="mat-header-cell">Vos prospects AAA</td>
          <td class="mat-header-cell">Total</td>
        </tr>
        <tr class="mat-row">
          <td class="mat-cell"><b>Base brute</b></td>
          <td class="mat-cell">{{volumes.volumeCrmBrut}}</td>
          <td class="mat-cell">{{volumes.volumePrmChaudBrut}}</td>
          <td class="mat-cell">{{volumes.volumePrmBrut}}</td>
          <td class="mat-cell">{{volumes.volumeGlobalBrut}}</td>
        </tr>
        <tr class="mat-row">
          <td class="mat-cell"><b>Base contactable</b></td>
          <td class="mat-cell">{{volumes.volumeCrmContactable}}</td>
          <td class="mat-cell">{{volumes.volumePrmChaudContactable}}</td>
          <td class="mat-cell">{{volumes.volumePrmContactable}}</td>
          <td class="mat-cell">{{volumes.volumeGlobalContactable}}</td>
        </tr>
      </table>
    </div>

    <div class="m-20">
      <b>Base brute:</b> contacts particuliers avec ou sans véhicule (Renault, Dacia et autres marques).<br>
      <b>Base contactable:</b> contacts particuliers ayant au moins un moyen de communication valide (@, tél, adresse
      postale) et utilisable (consentement donné).<br><br>
      <b>Vos clients juridiques:</b> les contacts pour lesquels a été identifiée une transaction financière avec votre
      affaire R1.<br>
      <b>Vos prospects:</b> les prospects ayant émis la volonté d'être contactés par votre affaire et qui n'en sont pas
      déjà clients au sens juridique.<br>
      <b>Vos prospects AAA:</b> les prospects habitant sur votre zone de chalandise.
    </div>

  </div>
</div>