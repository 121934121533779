import { IDr } from 'app/shared/model/dr.model';
import { IGroupe } from 'app/shared/model/groupe.model';
import { IUtilisateurAffaire } from 'app/shared/model/utilisateur-affaire.model';
import { IServices } from './services.model';

export interface IUtilisateur {
  [x: string]: any;
  id?: number;
  ipn?: string;
  nom?: string;
  prenom?: string;
  profil?: IGroupe;
  profilDto?: IGroupe;
  serviceDto?: IServices;
  service?: IServices;
  ref_telephone?: string;
  ref_portable?: string;
  ref_adresse?: string;
  ref_mail?: string;
  sms?: boolean;
  email?: boolean;
  courrier?: boolean;
  drs?: IDr[];
  utilisateurAffaires?: IUtilisateurAffaire[];
  derniere_connexion?: number;
  notification?: any;
}

export class Utilisateur implements IUtilisateur {
  constructor(
    public id?: number,
    public ipn?: string,
    public nom?: string,
    public prenom?: string,
    public profil?: IGroupe,
    public profilDto?: IGroupe,
    public serviceDto?: IServices,
    public service?: IServices,
    public ref_telephone?: string,
    public ref_adresse?: string,
    public ref_portable?: string,
    public ref_mail?: string,
    public sms?: boolean,
    public email?: boolean,
    public courrier?: boolean,
    public drs?: IDr[],
    public utilisateurAffaires?: IUtilisateurAffaire[],
    public derniere_connexion?: number,
    public notification?: any
  ) { }
}
