import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ProfileService implements Resolve<any> {
  timeline: any;
  about: any;
  photosVideos: any;

  timelineOnChanged: BehaviorSubject<any>;
  aboutOnChanged: BehaviorSubject<any>;
  photosVideosOnChanged: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    // Set the defaults
    this.timelineOnChanged = new BehaviorSubject({});
    this.aboutOnChanged = new BehaviorSubject({});
    this.photosVideosOnChanged = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param route
   * @param state
   * @returns
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([]).then(() => {
        resolve();
      }, reject);
    });
  }
}
