import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { oAuthModuleConfig } from '../config/oauth-module.config';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { finalize } from 'rxjs/operators';
import { AuthorizationService } from '../authorization.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private moduleConfig;
  private isswitcheduser;
  constructor(
    private oAuthService: OAuthService,
    private authStorage: OAuthStorage,
    private _fuseProgressBarService: FuseProgressBarService,
    private authorizationService: AuthorizationService
  ) {
    this.moduleConfig = oAuthModuleConfig;
    this.authorizationService.isswitcheduserflag.subscribe(x => {
      this.isswitcheduser = x;
    });
  }

  private checkUrl(url: string): boolean {
    let found = false;
    this.moduleConfig.resourceServer.allowedUrls.forEach((u, index) => {
      if (url.startsWith(u)) {
        found = true;
      }
    });

    return found;
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._fuseProgressBarService.show();
    this._fuseProgressBarService.setMode('indeterminate');

    const url = request.url.toLowerCase();

    if (!this.moduleConfig) {
      return next.handle(request);
    }
    if (!this.moduleConfig.resourceServer) {
      return next.handle(request);
    }
    if (!this.moduleConfig.resourceServer.allowedUrls) {
      return next.handle(request);
    }
    if (!this.checkUrl(url)) {
      return next.handle(request);
    }

    // add authorization header with jwt token if available
    const token = this.oAuthService.getAccessToken();
    const tokenscope = this.authStorage.getItem('token_scope');
    const tokenswitcheuser = this.authStorage.getItem('token_switcher');

    // let headers = request.headers.set(
    //     "apiKey",
    //     "3ceed53c-6740-489d-93e3-0646ba45c06a"
    // );

    let headers;
    if (
        request.url.includes('/api/upload/')
        || request.url.includes('/api/sourceexterne/upload/')
        || request.url.includes('/api/pmr/maj/data')
    ) {
      headers = new HttpHeaders();
    } else {
      headers = request.headers.set('Content-Type', 'application/json');
    }

    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }
    //  if (tokenscope) {
    //      headers = headers.set("AuthorizationScope", `Bearer ${tokenscope}`);

    // }

    if (this.isswitcheduser) {
      if (tokenswitcheuser) {
        headers = headers.set('AuthorizationScope', `Bearer ${tokenswitcheuser}`);
      } else {
        if (tokenscope) {
          headers = headers.set('AuthorizationScope', `Bearer ${tokenscope}`);
        }
      }
    } else {
      if (tokenscope) {
        headers = headers.set('AuthorizationScope', `Bearer ${tokenscope}`);
      }
    }

    request = request.clone({ headers });

    // request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });

    return next.handle(request).pipe(finalize(() => this._fuseProgressBarService.hide()));
  }
}
