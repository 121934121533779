import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { createRequestOption } from 'app/shared/util/request-util';
import { ITemplateSurcharge } from 'app/shared/model/template-surcharge.model';
import { IDashboardMessage } from 'app/shared/model/dashboardmessage.model';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { IAffaire } from '../model/affaire.model.js';
type EntityResponseType = HttpResponse<ITemplateSurcharge>;
type EntityResponseTypeDash = HttpResponse<IDashboardMessage>;
type EntityArrayResponseTypeAff = HttpResponse<IAffaire[]>;
type EntityArrayResponseType = HttpResponse<ITemplateSurcharge[]>;

@Injectable({ providedIn: 'root' })
export class DashboardMessagePersonaliseService {
  public resourceUrl = environment.apiURL + '/dashboardmessagepersonalise';

  constructor(protected http: HttpClient) {}

  create(dashboardMessagePersonalise: any): Observable<EntityResponseType> {
    return this.http.post<any>(this.resourceUrl, dashboardMessagePersonalise, { observe: 'response' });
  }
  getbyBandeauNumber(bandeau: any): Observable<EntityResponseTypeDash> {
    return this.http.get<IDashboardMessage>(this.resourceUrl + '/' + bandeau, { observe: 'response' });
  }
  getAllAffairesGroupes(): Observable<EntityArrayResponseTypeAff> {
    return this.http.get<any>(this.resourceUrl + '/getallaffairesgroupes', { observe: 'response' });
  }
  findGroupAffaireChildren(id: any): Observable<EntityResponseType> {
    return this.http.get<any>(this.resourceUrl + '/findGroupAffaireChildren/' + id, { observe: 'response' });
  }

  getAllDashboardMessagesPersonalise(): Observable<EntityArrayResponseType> {
    return this.http.get<ITemplateSurcharge[]>(this.resourceUrl, { observe: 'response' }).pipe(map((res: EntityArrayResponseType) => res));
  }
}
