import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';
import { NotificationService } from 'app/shared/services/notification.service';
import { Message } from 'app/shared/constants/message.constants';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RelanceursComponent } from 'app/main/apps/relanceurs/relanceurs/relanceurs.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogModalComponent } from 'app/shared/services/dialog-modal/dialog-modal.component';
import { LoadingService } from 'app/shared/services/loading.service';
@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

  totalRequests = 0;
  requestsCompleted = 0;

  constructor(public toasterService: NotificationService, private _matDialog: MatDialog, private loader: LoadingService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes('/token')) {
      this.loader.show();
      this.totalRequests++;
    }
    return next.handle(req).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
          if (evt.body && evt.body.success) {
            this.toasterService.showSuccess(Message.MESSAGE_UPDATE, '', { positionClass: 'toast-top-right' });
          }
        }
      }),
      finalize(() => {
        if (this.totalRequests > 0 && !req.url.includes('/token')) {
          this.requestsCompleted++;
          if (this.requestsCompleted === this.totalRequests) {
            this.loader.hide();
            this.totalRequests = 0; 
            this.requestsCompleted = 0;
          }
        }
      }),
      catchError((err: any) => {
        if (err.url.includes('/api/upload/')) {
          return throwError(err);
        }
        if (err instanceof HttpErrorResponse) {
          try {
            this._matDialog.open(DialogModalComponent, {
              width: '650px',
              data: { title: 'Avertissement', subject: Message.MESSAGE_ERR_PROD },
            });

            // this.toasterService.showError(Message.MESSAGE_ERR_PROD, "JADE PROBLEM", { positionClass: 'toast-top-right' });
          } catch (e) {
            this.toasterService.showError(Message.MESSAGE_ERR_PROD, '', { positionClass: 'toast-top-right' });
          }
          //log error
        }
        return of(err);
      })
    );
  }
}
