<div class="container" style="min-width: 100% !important">
  <h3 mat-dialog-title class="title">Coordonnées pour correspondance</h3>

  <form class="example-form">
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>E-mail</mat-label>
      <input type="email" matInput [(ngModel)]="data.ref_mail" [formControl]="emailFormControl" placeholder="Ex. Jack@example.com" />

      <mat-error *ngIf="emailFormControl.hasError('email')"> S'il vous plaît, mettez une adresse email valide </mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>Tél fixe </mat-label>
      <input matInput [(ngModel)]="data.ref_telephone" [ngModelOptions]="{ standalone: true }" />
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>Tél portable</mat-label>
      <input matInput [(ngModel)]="data.ref_portable" [ngModelOptions]="{ standalone: true }" />
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>Adresse postale </mat-label>
      <textarea matInput [(ngModel)]="data.ref_adresse" [ngModelOptions]="{ standalone: true }"></textarea>
    </mat-form-field>

    <div mat-dialog-actions>
      <button mr-16 mat-raised-button color="accent" type="submit" (click)="update()">Valider</button>
      <button mat-focus-indicator mat-accent mr-16 mat-raised-button mat-button-base mat-dialog-close (click)="onNoClick()" tabindex="-1">
        Annuler
      </button>
    </div>
  </form>
</div>
