export const locale = {
  lang: 'en',
  data: {
    NAV: {
      APPLICATIONS: 'Applications',
      DASHBOARDS: 'Dashboards',
      CALENDAR: 'Calendar',
      CAMPAIGN: 'Campaign scripting ',
      DATA: 'Data Dictionary ',
      LOG: 'Log access ',
      SEARCH: 'Advanced search',
      NETWORK: 'My Network',
      NETWORK_S1: 'Business management',
      NETWORK_S2: 'Management of signatories',
      CONFIG: 'Settings',
      ECOMMERCE: 'E-Commerce',
      ACADEMY: 'Academy',
      MAIL: {
        TITLE: 'Mail',
        BADGE: '25',
      },
      MAIL_NGRX: {
        TITLE: 'Mail Ngrx',
        BADGE: '13',
      },
      CHAT: 'Chat',
      FILE_MANAGER: 'Tools',
      CONTACTS: 'Contacts',
      TODO: 'To-Do',
      SCRUMBOARD: 'Scrumboard',
    },
  },
};
