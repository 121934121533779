import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'environments/environment';

export const authConfig: AuthConfig = {
  // Urls of EndPoints
  issuer: environment.issuer,
  loginUrl: environment.loginUrl,
  userinfoEndpoint: environment.userinfoEndpoint,
  tokenEndpoint: environment.tokenEndpoint,
  clientId: environment.clientId,
  dummyClientSecret: environment.cliensecret,
  scope: environment.scope,
  responseType: 'code',
  requireHttps: true,
  /* Common conf */
  redirectUri: window.location.origin + '/login-callback',
  // silentRefresh
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  silentRefreshShowIFrame: true,
  silentRefreshIFrameName: 'rnlt-angular-oauth-oidc-silent-refresh-iframe',
  showDebugInformation: true,
  sessionChecksEnabled: false,
  strictDiscoveryDocumentValidation: false,
  clearHashAfterLogin: false,
};
