import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { createRequestOption, SearchWithPagination } from 'app/shared/util/request-util';
import { IMenu, Menu } from 'app/shared/model/menu.model';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigation } from '@fuse/types';
import { environment } from 'environments/environment';
import { IUtilisateurProfil } from '../model/profil.model';
import { IUtilisateur } from '../model/utilisateur.model';
type EntityResponseType = HttpResponse<IMenu>;
type EntityArrayResponseType = HttpResponse<IMenu[]>;
type EntityResponseType2 = HttpResponse<IUtilisateur>;

@Injectable({ providedIn: 'root' })
export class MenuService {
  public resourceUrl = environment.apiURL + '/menus';
  public resourceProfil = environment.apiURL + '/utilisateur/profil';
  public resourcemenuprofile = environment.apiURL + '/profils';

  navigation: any;

  navigationme: FuseNavigation[] = [
    {
      id: 'applications',
      title: 'Applications',
      translate: 'NAV.APPLICATIONS',
      type: 'group',
      icon: 'apps',
      children: [],
    },
  ];
  idProfil: number;

  constructor(private http: HttpClient, private _fuseNavigationService: FuseNavigationService) {}

  create(menu: IMenu): Observable<EntityResponseType> {
    return this.http.post<IMenu>(this.resourceUrl, menu, { observe: 'response' });
  }

  update(menu: IMenu): Observable<EntityResponseType> {
    return this.http.put<IMenu>(`${this.resourceUrl}/${menu.id}`, menu, { observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IMenu>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IMenu[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  geMenus() {
    return this.http.get<IMenu>(`${this.resourceUrl}`);
  }

  search(req: SearchWithPagination): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<Menu[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  // delete(id: number): Observable<HttpResponse<{}>> {
  //   return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  // }

  delete(id: any): Observable<any> {
    return this.http.delete(`${this.resourceUrl}/${id}`);
  }

  getMenuSide(): Observable<HttpResponse<{}>> {
    return this.http.get(this.resourceUrl + '/token/menuside', { observe: 'response' });
  }

  AddMenuToRole(id1, id2): Observable<EntityResponseType> {
    return this.http.post<IMenu>(`${this.resourceUrl}/${id1}/roles/${id2}`, '', { observe: 'response' });
  }

  DeleteRoleMenu(id1, id2): Observable<EntityResponseType> {
    return this.http.delete(`${this.resourceUrl}/${id1}/roles/${id2}`, { observe: 'response' });
  }

  AddMenuToModule(id1, id2): Observable<EntityResponseType> {
    return this.http.post<IMenu>(`${this.resourceUrl}/${id1}/modules/${id2}`, '', { observe: 'response' });
  }

  DeleteMenuModule(id1, id2): Observable<EntityResponseType> {
    return this.http.delete(`${this.resourceUrl}/${id1}/modules/${id2}`, { observe: 'response' });
  }

  AddMenuToProfil(id1, id2): Observable<EntityResponseType> {
    return this.http.post<IMenu>(`${this.resourceUrl}/${id1}/profiles/${id2}`, '', { observe: 'response' });
  }

  DeleteMenuProfil(id1, id2): Observable<EntityResponseType> {
    return this.http.delete(`${this.resourceUrl}/${id1}/profiles/${id2}`, { observe: 'response' });
  }
  getSideMenu() {
    return this.http.get(this.resourceUrl + '/token/menuside', { observe: 'response' });
  }
  getMenuSideProfil(id: number): Observable<EntityResponseType> {
    return this.http.get<IMenu>(`${this.resourceUrl}/${id}/menuside`, { observe: 'response' });
  }
  getSwitchedSideMenu(): Observable<EntityResponseType> {
    return this.http.get(this.resourceUrl + '/token/menuside', { observe: 'response' });
  }

  updateRole(data): Observable<Menu> {
    return this.http.put<Menu>(this.resourceUrl, JSON.stringify(data), { observe: 'response' });
  }
  callme() {
    return this.http.get(this.resourceUrl + '/profil');
  }

  getProfil(): Observable<EntityResponseType2> {
    return this.http.get<IUtilisateurProfil>(this.resourceProfil, { observe: 'response' });
  }
  /*
   * load main menu
   */

  loadMenu() {
    //  this._fuseNavigationService.unregister('main');
    this.getProfil().subscribe((res: any) => {
      this.idProfil = res.body.profilDto.id;
      this.getMenuSide().subscribe((menu: any) => {
        this.navigationme[0].children = [];
        this.navigationme[0].children = this.navigationme[0].children.concat(menu.body);
        this.navigation = this.navigationme;
        //  console.log('load 2 navigation',this.navigation)
        // Register the navigation to the service
        this._fuseNavigationService.unregister('main');
        this._fuseNavigationService.register('main', this.navigation);
        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');
      });
    });
  }
  /*
   * load sitched menu
   */
  loadSiwtchMenu() {
    // this._fuseNavigationService.unregister('main');
    this.getSwitchedSideMenu().subscribe((menu: any) => {
      this.navigationme[0].children = [];
      this.navigationme[0].children = this.navigationme[0].children.concat(menu.body);
      this.navigation = this.navigationme;
      // Register the navigation to the service
      // unregister(key)
      this._fuseNavigationService.unregister('main');
      this._fuseNavigationService.register('main', this.navigation);
      // Set the main navigation as our current navigation
      this._fuseNavigationService.setCurrentNavigation('main');
    });
  }

  getMenuByProfil(id: number): Observable<EntityResponseType> {
    return this.http.get<IMenu>(`${this.resourcemenuprofile}/${id}/menus`, { observe: 'response' });
  }
}
