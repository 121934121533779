<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
  <div class="logo">
    <a [routerLink]="['/apps/dashboards/project']">
      <img class="logo-icon" src="assets/images/logos/{{logo}}" />
    </a>
  </div>

  <div class="buttons">
    <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
      <mat-icon *ngIf="!oponed" class="secondary-text" matTooltip="Masquer / démasquer le menu"> arrow_back</mat-icon>
      <mat-icon *ngIf="oponed" class="secondary-text"> arrow_forward</mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
      <mat-icon *ngIf="oponed" class="secondary-text"> arrow_forward</mat-icon>
    </button>
  </div>
</div>

<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground" fusePerfectScrollbar
  [fusePerfectScrollbarOptions]="{ suppressScrollX: true }">
  <div class="user" fxLayout="column" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
    <div *ngIf="currentUser" class="h3 username">{{ nomuser }} {{ prenomuser }} : {{ ipn }}</div>

    <div class="h5 email hint-text mt-8">Affaire : {{ defaultAffaire }}</div>
    <div *ngIf="currentUser" class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
      <img class="avatar" class="{{ profileclass }}" src="https://outlook.office.com/owa/service.svc/s/GetPersonaPhoto?email={{ currentUser.uid }}@renault.com&size=HR64x64"
        onerror="this.src='./assets/images/avatars/profile.jpg';" />
    </div>
  </div>

  <div class="navbar-content">
    <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
  </div>
</div>