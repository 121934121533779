<div id="about" class="p-24" fxLayout="row wrap">
  <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="50">
    <div class="profile-box info-box general" fxLayout="column">
      <div class="accent">
        <div class="title">Affaires de rattachement</div>
      </div>

      <div class="content">
        <div class="info-line">
          <div class="title">Code RRF :</div>
          <div class="info">{{ code }}</div>
        </div>

        <div class="info-line">
          <div class="title">Nom commercial de l'affaire :</div>
          <div class="info">{{ defaultAffaire }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
