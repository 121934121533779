import { Injectable } from '@angular/core';
import { OAuthService, UserInfo } from 'angular-oauth2-oidc';

import { Router } from '@angular/router';

import { authConfig } from './config/authConfig';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Authorization } from './authorization.model';

import { AuthenticationService } from 'app/shared/services/authentication.service';

import { filter, first } from 'rxjs/operators';
import { UtilisateurService } from '../services/utilisateur.service';
import { MenuService } from 'app/shared/services/menu.service';
import { User } from '../model/user.model';
import { Utilisateur } from '../model/utilisateur.model';

@Injectable({ providedIn: 'root' })
export class AuthorizationService {
  private rnltUserInfo: UserInfo;
  private switchUserInfo: UserInfo;
  private errorDuringBootstrap: any = undefined;

  private currentUserSubject: BehaviorSubject<UserInfo>;
  public currentUser: Observable<UserInfo>;
  public infocurrentUser: BehaviorSubject<Utilisateur> = new BehaviorSubject<Utilisateur>(new Utilisateur());

  private user: any;
  private isDone = false;

  isswitcheduserflag = new BehaviorSubject<boolean>(false);

  private doneAuthenticatingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private reloadDashbord = new BehaviorSubject<boolean>(false);
  isReloadDashboard = this.reloadDashbord.asObservable();

  constructor(
    private oAuthService: OAuthService,
    private router: Router,
    private apiRequest: UtilisateurService,
    private authentication: AuthenticationService,
    private menuservice: MenuService
  ) {
    this.checkswitcheruseronstorage();
    if (this.isswitcheduserflag.getValue()) {
      this.currentUserSubject = new BehaviorSubject<UserInfo>(JSON.parse(sessionStorage.getItem('switcheruser')));
      // alert("true ... "+"storage : "+iswitcher);
    } else {
      this.currentUserSubject = new BehaviorSubject<UserInfo>(JSON.parse(sessionStorage.getItem('currentUser')));
      //         // alert("false ... "+"storage : "+iswitcher);
    }

    this.currentUser = this.currentUserSubject.asObservable();
    this.authentication.setcurrentuser(this.currentUser);

    // this.apiRequest.createUserProfil().subscribe((res :any)=>{
    //   this.infocurrentUser.next(res.body);
    //    console.log("authorisation constructor : ",res.body.serviceDto.groupecode);
    //  });
  }

  public checkswitcheruseronstorage() {
    const iswitcher = sessionStorage.getItem('iswitcher');

    if (iswitcher != null && iswitcher != undefined && iswitcher != 'undefined' && iswitcher == 'true') {
      this.isswitcheduserflag.next(true);
    } else {
      this.isswitcheduserflag.next(false);
    }
  }

  public switchToggleUser(etat: boolean) {
    if (etat) {
      sessionStorage.setItem('iswitcher', 'true');
    } else {
      sessionStorage.setItem('iswitcher', 'false');
    }
    this.checkswitcheruseronstorage();
  }

  public get currentUserValue(): UserInfo {
    return this.currentUserSubject.value;
  }

  static isAllowed(authorizationIHave: Authorization, authorizationsINeed: Authorization): any {
    return (
      Authorization[authorizationsINeed] === Authorization.NONE ||
      authorizationIHave === Authorization.ALL ||
      authorizationIHave === authorizationsINeed ||
      1
    );
  }

  async bootstrapAuthService(): Promise<void> {
    try {
      this.oAuthService.configure(authConfig);
      await this.oAuthService.loadDiscoveryDocumentAndTryLogin();
      if (!this.oAuthService.hasValidAccessToken()) {
        await this.login();
      } else {
        this.oAuthService.setupAutomaticSilentRefresh();
        await this.loginScope();
        await this.loadprofile();
        let currenturl = sessionStorage.getItem('currenturl');
        if (currenturl === null) {
          currenturl = '';
        }
        if (window.location.pathname === '/login-callback') {
          this.router.navigate([currenturl]);
        }
        // else
        //  this.router.initialNavigation();
      }
    } catch (e) {
      this.errorDuringBootstrap = e;
      throw e;
    }
  }

  private async login(): Promise<void> {
    this.oAuthService.initCodeFlow('');
    await new Promise<void>(() => { });
  }

  private async loginScope(): Promise<void> {
    return this.apiRequest
      .authenticate()
      .toPromise()
      .then(response => {
        sessionStorage.setItem('token_scope', response.body.token);
        this.authentication.login();
        this.isDone = true;
      });
  }
  private async loadprofile(): Promise<void> {
    return this.apiRequest
      .createUserProfil()
      .toPromise()
      .then(response => {
        this.infocurrentUser.next(response.body);
        // console.log("loadprofile : ",response.body);
      });
  }

  //  public async switcheUtilisateur(ipn:string): Promise<void> {
  public switcheUtilisateur(ipn: string): void {
    const iswitcher = sessionStorage.getItem('iswitcher');
    // if(iswitcher=='true'){
    if (!this.isswitcheduserflag.getValue()) {
      // properties['Value'] = (IsItMuted === true) ? 'On' : 'Off';
      const infouser = sessionStorage.getItem('switchuserinfo');

      if (infouser != null && infouser != undefined && infouser != 'undefined') {
        var switchuserinforecu = JSON.parse(sessionStorage.getItem('switchuserinfo')) as UserInfo;
        this.switchUserInfo = switchuserinforecu;

        this.currentUserSubject.next(this.switchUserInfo);
        // this.switchToggleUser();
      } else {
        this.apiRequest
          .authenticatetoswitcher(ipn)
          .toPromise()
          .then(response => {
            // this.apiRequest.authenticatetoswitcher(ipn).toPromise().then(response => {
            sessionStorage.setItem('token_switcher', response.token);
            this.apiRequest
              .getInfoSwitcherUser(ipn)
              .toPromise()
              .then(response => {
                sessionStorage.setItem('switchuserinfo', JSON.stringify(response));
                // this.authentication.login();
                switchuserinforecu = JSON.parse(sessionStorage.getItem('switchuserinfo')) as UserInfo;

                this.currentUserSubject.next(switchuserinforecu);

                this.isDone = true;
                // this.switchToggleUser();
              });
          });

        //   this.switchUserInfo = {
        //    "ipn":"pf00000",
        //   "firstname":"ali",
        //   "lastname":"larbi",
        //   "mail":"larbi@gmail.com",
        //   "sub":""
        // }
        // sessionStorage.setItem('switchuserinfo',JSON.stringify(this.switchUserInfo));
      }
    } else {
      this.apiRequest
        .deconnecteruserswitcher()
        .toPromise()
        .then(response => {
          // this.apiRequest.authenticatetoswitcher(ipn).toPromise().then(response => {
          sessionStorage.removeItem('token_switcher');

          sessionStorage.removeItem('switchuserinfo');
          // this.authentication.login();

          this.currentUserSubject.next(this.rnltUserInfo);
          // this.switchToggleUser();
        })
        .catch(error => console.log(error));
    }
  }
  switchon(ipn?: string, redirect = false) {
    sessionStorage.removeItem('apvSite');//clear apv site
    const infouser = sessionStorage.getItem('switchuserinfo');
    if (infouser != null && infouser != undefined && infouser != 'undefined') {
      const switchuserinforecu = JSON.parse(sessionStorage.getItem('switchuserinfo')) as UserInfo;
      this.switchUserInfo = switchuserinforecu;
      this.isswitcheduserflag.next(true);
      this.currentUserSubject.next(this.switchUserInfo);
      this.switchToggleUser(true);
      this.authentication.setcurrentuser(this.currentUser);
      this.loadprofile();
      if (redirect) {
        this.router.navigate(['apps/dashboards/project']);
        this.reloadDashbord.next(true);
      }
    } else {
      this.apiRequest
        .authenticatetoswitcher(ipn)
        .toPromise()
        .then(response => {
          sessionStorage.setItem('token_switcher', response.token);
          this.apiRequest
            .getInfoSwitcherUser(ipn)
            .toPromise()
            .then(response => {
              sessionStorage.setItem('switchuserinfo', JSON.stringify(response));
              // this.authentication.login();
              const switchuserinforecu = JSON.parse(sessionStorage.getItem('switchuserinfo')) as UserInfo;

              this.currentUserSubject.next(switchuserinforecu);
              this.isswitcheduserflag.next(true);

              this.isDone = true;
              this.loadprofile();
              this.switchToggleUser(true);
              this.authentication.setcurrentuser(this.currentUser);
              this.menuservice.loadSiwtchMenu();
              if (redirect) {
                this.router.navigate(['apps/dashboards/project']);
                this.reloadDashbord.next(true);
              }
            });
        });
    }
  }
  switchoff(flag: boolean, redirect = false) {
    sessionStorage.removeItem('apvSite');//clear apv site
    const ipn = '';
    if (flag) {
      this.apiRequest
        .deconnecteruserswitcher()
        .toPromise()
        .then(response => {
          // this.apiRequest.authenticatetoswitcher(ipn).toPromise().then(response => {
          // sessionStorage.setItem('token_switcher', response.token);
          this.switchToggleUser(false);
          this.currentUserSubject.next(this.getUserInfo());
          this.loadprofile();
          this.menuservice.loadMenu();
        });
    } else {
      this.switchToggleUser(false);
      this.loadprofile();
      this.currentUserSubject.next(this.getUserInfo());
    }
    sessionStorage.removeItem('switchuserinfo');
    if (redirect) {
      this.router.navigate(['apps/dashboards/project']);
      this.reloadDashbord.next(true);
    }
  }

  logout(): void {
    this.oAuthService.logOut(false);
    const now: Date = new Date();
    sessionStorage.setItem('session_terminated_at', now.toLocaleDateString() + ' ' + now.toTimeString());
  }

  get bootstrapError(): any {
    return this.errorDuringBootstrap;
  }

  getUserInfo(): UserInfo {
    if (this.rnltUserInfo) {
      return this.rnltUserInfo;
    }

    // const accessToken = this.oAuthService.getAccessToken();
    const accessToken = sessionStorage.getItem('access_token');

    if (!accessToken) {
      throw new Error('No access token');
    }

    const decodedToken = this.extractAccessTokenData(accessToken);

    this.rnltUserInfo = decodedToken;

    sessionStorage.setItem('currentUserRenault', JSON.stringify(this.rnltUserInfo));

    this.currentUserSubject.next(this.rnltUserInfo);

    return this.rnltUserInfo;
  }

  private extractAccessTokenData(accessToken: string) {
    const tokenBodyFirstIndex = this.getAccessTokenBodyStartIndex(accessToken);
    const tokenBodyLastIndex = this.getAccessTokenBodyEndIndex(accessToken, tokenBodyFirstIndex);

    const accessTokenBody = this.getAccessTokenBody(accessToken, tokenBodyFirstIndex, tokenBodyLastIndex);
    const decodedToken = atob(accessTokenBody);
    return JSON.parse(decodedToken) as UserInfo;
  }

  private getAccessTokenBodyStartIndex(accessToken: string) {
    return accessToken.indexOf('.') + 1;
  }

  private getAccessTokenBodyEndIndex(accessToken: string, tokenBodyFirstIndex: number) {
    return accessToken.indexOf('.', tokenBodyFirstIndex);
  }

  private getAccessTokenBody(accessToken: string, tokenBodyFirstIndex: number, tokenBodyLastIndex: number) {
    return accessToken.substring(tokenBodyFirstIndex, tokenBodyLastIndex);
  }

  getUserId(): any {
    if (this.rnltUserInfo) {
      return this.rnltUserInfo.uid;
    } else {
      return null;
    }
  }

  getIsDone(): boolean {
    return this.isDone;
  }

  public whenDoneAuthenticating(): Observable<boolean> {
    return this.doneAuthenticatingSubject.asObservable().pipe(
      filter((isDone: boolean) => isDone),
      first()
    );
  }
}
