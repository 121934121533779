import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { createRequestOption, SearchWithPagination } from 'app/shared/util/request-util';
import { IUtilisateur, Utilisateur } from 'app/shared/model/utilisateur.model';

import { environment } from 'environments/environment';
import { IUtilisateurAffaire } from 'app/shared/model/utilisateur-affaire.model';
import { EnvironmentService } from '../environment/environment.service';
import { IUtilisateurProfil } from '../model/profil.model';
import { IAffaire } from '../model/affaire.model';
type EntityResponseType = HttpResponse<IUtilisateur>;
type EntityArrayResponseType = HttpResponse<IUtilisateur[]>;
type AffaireArrayResponseType = HttpResponse<IAffaire[]>;
type UtilisateurAffaireResponseType = HttpResponse<IUtilisateurAffaire>;

@Injectable({ providedIn: 'root' })
export class UtilisateurService {
  public resourceUrl = environment.apiURL + '/utilisateurs';
  public resourceUrlList = environment.apiURL + '/utilisateurs/all';
  public resourceUser = environment.apiURL + '/utilisateur';
  public resourceProfil = environment.apiURL + '/utilisateur/profil';

  public resourceAffaireUser = environment.apiURL + '/affaires-utilisateurs';
  public switchdirect = environment.apiURL + '/authenticate/direct';
  public defaultAffaireUrl = environment.apiURL + '/defaultAffaire';
  // public infoSwitcherUrl = environment.apiURL + '/utilisateurs/switcher/'

  loginURL: string;
  loginSwitcherUrl: string;
  loginSwitcherUrloff: string;
  infoSwitcherUrl: string;
  constructor(protected http: HttpClient, private envConfig: EnvironmentService) {
    this.loginURL = this.envConfig.getLoginUrl();
    this.loginSwitcherUrl = this.envConfig.getloginSwitcherUrl();
    this.loginSwitcherUrloff = this.envConfig.getloginSwitcherUrlOff();
    this.infoSwitcherUrl = this.envConfig.getinfoSwitcherUrl();
  }

  create(utilisateur: IUtilisateur): Observable<EntityResponseType> {
    return this.http.post<IUtilisateur>(this.resourceUrl, utilisateur, { observe: 'response' });
  }

  update(utilisateur: IUtilisateur): Observable<EntityResponseType> {
    return this.http.put<IUtilisateur>(this.resourceUrl, utilisateur, { observe: 'response' });
  }

  createUserProfil(): Observable<EntityResponseType> {
    return this.http.get<IUtilisateurProfil>(this.resourceProfil, { observe: 'response' });
  }

  GetConnexion(ipn: string): Observable<EntityResponseType> {
    return this.http.post<IUtilisateur>(this.resourceUrl, ipn, { observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IUtilisateur>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  findUtilisateurAffaires(id: number): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.resourceUrl}/affaires/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IUtilisateur[]>(this.resourceUrl, { params: options, observe: 'response' });
  }
  queryList(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IUtilisateur[]>(this.resourceUrlList, { params: options, observe: 'response' });
  }
  ListSwitchUser(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IUtilisateur[]>(`${this.resourceUrlList}/v2`, { params: options, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: SearchWithPagination): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IUtilisateur[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  getCurrentUser(): Observable<any> {
    return this.http.get(this.resourceUser);
  }

  authenticate(): Observable<any> {
    return this.http.get(this.loginURL, { observe: 'response' });
  }
  authenticatetoswitcher(ipn: string): Observable<any> {
    const user = {
      username: ipn,
      password: ipn,
    };
    return this.http.post<IUtilisateurAffaire>(this.loginSwitcherUrl, JSON.stringify(user));
  }
  deconnecteruserswitcher(): Observable<any> {
    return this.http.get<IUtilisateur>(this.loginSwitcherUrloff, { observe: 'response' });
  }
  getInfoSwitcherUser(ipn: string): Observable<any> {
    return this.http.get<IUtilisateur>(this.infoSwitcherUrl + ipn);
  }

  AddUserAffaire(item): Observable<IUtilisateurAffaire> {
    return this.http.post<IUtilisateurAffaire>(this.resourceAffaireUser, JSON.stringify(item));
  }

  DeleteAffaireUser(item): Observable<IUtilisateurAffaire> {
    return this.http.delete<IUtilisateurAffaire>(this.resourceAffaireUser);
  }

  DeleteUserAffaire(id1: number, id2: number) {
    return this.http.delete(`${this.resourceAffaireUser}/utilisateurs/${id1}/affaires/${id2}`, { observe: 'response' });
  }

  GetUserAffaire(id1: number, id2: number) {
    return this.http.get(`${this.resourceAffaireUser}/${id1}/${id2}`);
  }

  GetServiceUser(id1: number) {
    return this.http.get(`${this.resourceUrl}/${id1}/service`);
  }
  GeteUserDr(id1: number) {
    return this.http.get(`${this.resourceUrl}/${id1}/drs`);
  }
  AddServiceToUser(id1, id2): Observable<EntityResponseType> {
    return this.http.post<IUtilisateur>(`${this.resourceUrl}/${id1}/services/${id2}`, '', { observe: 'response' });
  }

  DeleteServiceFromUser(id1, id2): Observable<IUtilisateur> {
    return this.http.delete(`${this.resourceUrl}/${id1}/services/${id2}`);
  }

  GetDefaultAffaire() {
    return this.http.get(`${this.defaultAffaireUrl}`);
  }

  getUserR1Affaires(ipn: string): Observable<AffaireArrayResponseType> {
    return this.http.get<IAffaire[]>(`${this.resourceAffaireUser}/affairesR1/${ipn}`, { observe: 'response' });
  }

  updateUserAffaires(userAffaires: any[]): Observable<IUtilisateurAffaire> {
    return this.http.post<any>(this.resourceAffaireUser + '/addUserAffaires', userAffaires);
  }

  deleteUserAffaires(userAffaires: any[]): Observable<IUtilisateurAffaire> {
    return this.http.post<any>(this.resourceAffaireUser + '/deleteUserAffaires', userAffaires);
  }

  export(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/export`, { observe: 'response' });
  }
}
