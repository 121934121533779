import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UtilisateurService } from 'app/shared/services/utilisateur.service';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  datconn: any;
  latest_date: string;
  /**
   * Constructor
   */
  constructor(private apiService: UtilisateurService, public datepipe: DatePipe) { }

  ngOnInit() {
    this.GetDateConnextion();
  }

  GetDateConnextion() {
    this.apiService.createUserProfil().subscribe((res: any) => {
      this.datconn = res.body.utilisateurDto.derniere_connexion;
      this.latest_date = this.datconn.substring(0, 10) + ' ' + this.datconn.substring(11, 19);
    });
  }
}
