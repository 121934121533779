import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';

import { MatCalendar, MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _moment from 'moment';
import { Moment } from 'moment';
const moment = _moment;

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent {
  @Input() public placeholder: string = null;
  @Input() public minDate = null;
  @Input() private format = 'DD/MM/YYYY';

  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  @ViewChild('calendar') calendar: MatCalendar<_moment.Moment>;
  selectedDate: _moment.Moment;

  value: any;

  computeddate: any;
  datecalcule: any;

  d: any;
  m: any;
  y: any;

  constructor(public dialogRef: MatDialogRef<DatePickerComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (Array.isArray(this.data.value)) {
      this.value = this.data.value[0];
    } else {
      this.value = this.data.value;
    }
    if (this.value && this.value.substring(0, 1) === '@') {
      const tab = this.value.substring(1).replaceAll('+', '').split('/');
      this.d = tab[0].substring(1);
      this.m = tab[1].substring(1);
      this.y = tab[2].substring(1);
      this.selectedDate = null;
      this.updatedate();
      this.computeddate = true;
    } else {
      if (this.value === undefined) {
        this.selectedDate = moment();
      } else {
        this.selectedDate = moment(this.value, this.format);
        this.datecalcule = this.selectedDate;
      }

      this.datecalcule = this.selectedDate.format(this.format);
      this.d = 'J';
      this.m = 'M';
      this.y = 'A';

      this.computeddate = false;
    }
  }

  updatedate(): void {
    const datec = moment();
    if (this.y.substr(0, 1) === 'A') {
      datec.add(this.y.substr(1), 'year');
    } else {
      datec.year(this.y);
    }
    if (this.m.substr(0, 1) === 'M') {
      datec.add(this.m.substr(1), 'month');
    } else {
      datec.month(this.m);
    }
    if (this.d.substr(0, 1) === 'J') {
      datec.add(this.d.substr(1), 'day');
    } else {
      datec.date(this.d);
    }

    this.datecalcule = datec.format(this.format);
    this.selectedDate = null;
    this.computeddate = true;
  }
  valid(): void {
    if (this.computeddate) {
      this.value = '@' + this.d + '/' + this.m + '/' + this.y;
    } else {
      this.value = moment(this.selectedDate).format(this.format);
    }
    this.dialogRef.close(this.value);
  }
  onSelect(event): void {
    this.datecalcule = event.format(this.format);
    this.d = 'J';
    this.m = 'M';
    this.y = 'A';
    this.computeddate = false;
  }
}
