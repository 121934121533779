<div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
  fxLayoutAlign.gt-sm="space-between center">
  <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
    <button mat-icon-button class="mr-0 mr-sm-16" (click)="cancel()">
      <mat-icon matTooltip="Retour au menu">arrow_back</mat-icon>
    </button>
    <mat-icon class="logo-icon s-32 mr-16" [@animate]="{ value: '*', params: { delay: '50ms', scale: '0.2' } }">
      {{ icon }}
    </mat-icon>
    <span class="logo-text h1 mr-16" [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }"
      [innerHTML]="pageTitle"> </span>
    <span class="logo-text h1" [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }"
      [innerHTML]="pageText | async"> </span>

  </div>

  <button *ngIf="!hidebt" [disabled]="disable" mat-raised-button (click)="saveForm()" class="fuse-white mt-24 mt-md-0">
    <span>{{ buttontype }}</span>
  </button>
</div>