import { FuseNavigation } from '@fuse/types';

export const navigation2: FuseNavigation[] = [
  {
    id: '',
    title: 'Applications',
    translate: 'NAV.APPLICATIONS',
    type: 'group',
    icon: 'apps',
    children: [],
  },
];
export const navigation: FuseNavigation[] = [
  {
    id: 'applications',
    title: 'Applications',
    translate: 'NAV.APPLICATIONS',
    type: 'group',
    icon: 'apps',
    children: [
      {
        id: null,
        title: 'Dashboards',
        translate: 'NAV.DASHBOARDS',
        type: 'item',
        icon: 'dashboard',
        url: '/apps/dashboards/project',
      },
      {
        id: 'Paramétrage',
        title: 'Paramétrage',
        type: 'collapsable',
        translate: 'NAV.CONFIG',
        icon: 'settings_applications',

        children: [
          {
            id: 'Configuration des modules',
            title: 'Configuration des modules',
            type: 'item',
            url: '/apps/modules',
            icon: 'view_module',
          },
          {
            id: 'Gestion des modèles',
            title: 'Gestion des modèles',

            type: 'item',
            url: '/pages/errors/error-500',
          },
          {
            id: 'Gestion des utilisateurs',
            title: 'Gestion des utilisateurs',
            icon: 'account_box',

            type: 'item',
            url: '/apps/users',
          },
          {
            id: 'Gestion des groupes',
            title: 'Gestion des groupes',
            icon: 'group',
            type: 'item',
            url: '/apps/groupes',
          },
          {
            id: 'Gestion des profiles',
            title: 'Gestion des profiles',
            icon: 'person_outline',
            type: 'item',
            url: '/apps/roles',
          },
          {
            id: 'Gestion des permissions',
            title: 'Gestion des permissions',
            icon: 'pan_tool',
            type: 'item',
            url: '/apps/droits',
          },
          {
            id: 'Gestion des environnements',
            title: 'Gestion des environnements',
            icon: 'settings',
            type: 'item',
            url: '/apps/environnements',
          },
          {
            id: 'Gestion des menus',
            title: 'Gestion des menus',
            icon: 'menu',
            type: 'item',
            url: '/apps/menus',
          },
          {
            id: 'Gestion des directions régionales',
            title: 'Gestion des directions régionales',
            icon: 'directions',
            type: 'item',
            url: '/apps/directions-regionales',
          },
        ],
      },
      {
        id: 'Sénarisation des campagnes',
        title: 'Sénarisation des campagnes',
        translate: 'NAV.CAMPAIGN',
        type: 'collapsable',
        icon: 'business',
        children: [
          {
            id: 'Gérer les cibles',
            title: 'Gestion des cibles',
            type: 'item',
            icon: 'filter_tilt_shift',
            url: '/apps/ciblages',
          },
          {
            id: 'Gérer les campagnes',
            title: 'Gestion des campagnes',
            type: 'item',
            icon: 'business',
            url: '/apps/campagnes',
          },
          {
            id: 'Gestion des modèles ',
            title: 'Gestion des modèles ',
            type: 'item',
            icon: 'filter_tilt_shift',
            url: '/apps/modeles',
          },
          {
            id: 'Mes commandes',
            title: 'Mes commandes',
            type: 'item',
            url: '/apps/e-commerce/orders',
          },

          {
            id: ' Gestion des courriers',
            title: ' Gestion des courriers',
            type: 'item',
            url: '/apps/e-commerce/orders/1',
          },
          {
            id: 'Param',
            title: 'Paramétrage',
            type: 'collapsable',
            translate: 'NAV.CONFIG',
            icon: 'settings_applications',
            children: [
              {
                id: 'Gérer les pressions',
                title: 'Gérer les pressions',
                type: 'item',
                icon: 'business',
                url: '/apps/pressions',
              },
              {
                id: 'Gérer les relanceurs',
                title: 'Gérer les relanceurs',
                type: 'item',
                icon: 'notifications_active',
                url: '/apps/relanceurs',
              },
              {
                id: 'Gérer les canaux',
                title: 'Gérer les canaux',
                type: 'item',
                icon: 'swap_horiz',
                url: '/apps/canals',
              },
              {
                id: 'Gérer les routeurs',
                title: 'Gérer les routeurs',
                type: 'item',
                icon: 'swap_horizontal_circle',
                url: '/apps/routeurs',
              },
            ],
          },
        ],
      },

      {
        id: 'Dictionnaire de données',
        title: 'Dictionnaire de données',
        translate: 'NAV.DATA',
        type: 'collapsable',
        icon: 'data_usage',
        children: [
          {
            id: 'Gestion des critères',
            title: 'Gestion des critères',
            translate: 'NAV.DATA',
            type: 'item',
            icon: 'data_usage',
            url: '/apps/criteres',
          },

          {
            id: 'Gestion des tables',
            title: 'Gestion des tables',
            translate: 'NAV.DATA',
            type: 'item',
            icon: 'table_chart',
            url: '/apps/tables',
          },
        ],
      },

      {
        id: 'Accés aux log',
        title: 'Accés aux log',
        translate: 'NAV.LOG',
        type: 'item',
        icon: 'history',
        url: '/apps/scenarisations',
      },
      {
        id: 'Recherche  avancées',
        title: 'Recherche  avancées',
        translate: 'NAV.SEARCH',
        type: 'item',
        icon: 'image_search',
        url: '/apps/dictionnaire-de-donnees',
      },

      {
        id: 'Outils',
        title: 'Outils',
        translate: 'NAV.FILE_MANAGER',
        type: 'item',
        icon: 'folder',
        url: '/apps/file-manager',
      },
      {
        id: 'calendar',
        title: 'Calendar',
        translate: 'NAV.CALENDAR',
        type: 'item',
        icon: 'today',
        url: '/apps/calendar',
      },
    ],
  },
  {
    id: 'Mon réseau',
    title: 'Mon réseau',
    type: 'collapsable',
    translate: 'NAV.NETWORK',
    icon: 'share',

    children: [
      {
        id: 'Gestion des affaires',
        title: 'Gestion des affaires',
        type: 'item',
        icon: 'business',
        translate: 'NAV.NETWORK_S1',
        url: '/apps/affaires',
      },
      {
        id: 'Gestion des signataires',
        title: 'Gestion des signataires',
        translate: 'NAV.NETWORK_S2',
        type: 'item',
        icon: 'signatory',
        url: '/pages/errors/error-500',
      },

      {
        id: 'coming-soon',
        title: 'Coming Soon',
        type: 'item',
        icon: 'alarm',
        url: '/pages/coming-soon',
      },
      {
        id: 'errors',
        title: 'Errors',
        type: 'collapsable',
        icon: 'error',
        children: [
          {
            id: '404',
            title: '404',
            type: 'item',
            url: '/pages/errors/error-404',
          },
          {
            id: '500',
            title: '500',
            type: 'item',
            url: '/pages/errors/error-500',
          },
        ],
      },

      {
        id: 'maintenance',
        title: 'Maintenance',
        type: 'item',
        icon: 'build',
        url: '/pages/maintenance',
      },
      {
        id: 'querybuilder',
        title: 'Querybuilder',
        type: 'item',
        icon: 'build',
        url: '/querybuilder',
      },
    ],
  },
];
