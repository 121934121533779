import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { IParametresGeneraux } from '../model/parametres-generaux.model';

type EntityResponseType = HttpResponse<IParametresGeneraux>;

@Injectable({
    providedIn: 'root',
})
export class GestionMaintenancesService {

    private resourceUrl = environment.apiURL + '/ParametresGeneraux';

    constructor(private http: HttpClient) { }

    getParametresGeneraux(): Observable<EntityResponseType> {
        return this.http.get<IParametresGeneraux>(`${this.resourceUrl}`, { observe: 'response' });
    }

    updateParametresGeneraux(parametresGeneraux: IParametresGeneraux): Observable<EntityResponseType> {
        return this.http.put<IParametresGeneraux>(this.resourceUrl, parametresGeneraux, { observe: 'response' });
    }

}