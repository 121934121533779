<span *ngIf="multiple">
  <mat-form-field class="qb-list">

    <mat-chip-list #chipList class="chip-grid-list">
      <ng-container *ngFor="let option of ngModel">
        <mat-chip [selectable]="selectable" [removable]="removable" (value)="(option)" (removed)="remove(option)">
          {{ updatelabel(option) }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>

      </ng-container>
      <input #optionInput [formControl]="optionCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)" />
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (closed)="onClosedEvent($event)">
      <mat-option *ngFor="let option of (filteredOptions | async)?.body" [value]="option.value">
        <div (click)="optionClicked($event, option)">
          <mat-checkbox [checked]="isChecked(option)" (change)="selected($event, option)"
            (click)="$event.stopPropagation()">
            {{ option.name }}
          </mat-checkbox>
        </div>
      </mat-option>




    </mat-autocomplete>


  </mat-form-field>
</span>
<span *ngIf="!multiple"  >
  <app-querybuilders-select-filter [disabled]="disabled" [(ngModel)]="ngModel[0]" [multiple]="multiple" [cible]="cible" [list]="list"
    (ngModelChange)="updatevalue(); onChange($event, rule)" [fields]="fields" [rule]="rule" [type]="'field'" class="qb-list">
  </app-querybuilders-select-filter>
</span>