import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QueryComponent } from './query-builder.component';
import { QueryBuilderModule } from 'angular2-query-builder';
import { SharedModule } from 'app/shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatCardModule } from '@angular/material/card';

import { ListComponent } from './list/list.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MY_DATE_FORMATS } from 'app/shared/util/date-format';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { SelectFilterComponent } from './select-filter/select-filter.component';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

@NgModule({
  declarations: [QueryComponent, ListComponent, DatePickerComponent, SelectFilterComponent],
  imports: [
    CommonModule,
    QueryBuilderModule,
    SharedModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatToolbarModule,
    FuseSharedModule,
    MatMomentDateModule,
  ],
  exports: [QueryComponent],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    // {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ],
})
export class QBuilderModule { }
