<h1 matDialogTitle>Choisir un utilisateur</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Choisir un utilisateur</mat-label>
    <mat-select [(value)]="ipn" (selectionChange)="onSelectionUser($event)">
      <input type="text" [formControl]="searchInput" matInput class="form-control" autocomplete="off"
        placeholder="Rechercher un utilisateur" />
      <mat-option *ngFor="let user of dataUsers" [value]="user.ipn">
        <b> {{ user.nom }} {{ user.prenom }}</b> ({{ user.ipn }}) , {{ user.nomAffaire }} ({{ user.codeAffaire }}
        ) <span *ngIf="user.derniere_connexion != undefined || user.derniere_connexion != null"> - </span> <b>
          {{user.derniere_connexion | date: 'dd/MM/yyyy' }}</b>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions class="pt-24">
  <button mat-raised-button class="mat-accent mr-16" type="submit"
    (click)="changeuser(); dialogRef.close(true)">Valider</button>
  <button mat-button (click)="dialogRef.close(false)">Quitter</button>
</div>
