import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Field, Option } from 'angular2-query-builder';
import { HttpResponse } from '@angular/common/http';
import { CritereService } from 'app/shared/services/critere.service';
type EntityArrayResponseTypeOption = HttpResponse<Option[]>;

@Component({
  selector: 'app-querybuilders-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss'],
})
export class SelectFilterComponent implements OnChanges, OnInit {
  @Input() fields: any;
  @Input() entities: any;
  @Input() type: any;
  @Input() rule: any;
  @Input() disabled: any;

  @Input() multiple: any;
  @Input() cible: any;
  @Input() list: any;

  @Input() ngModel: any;

  filteredfields: any;

  value: any;

  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  constructor(private critereService: CritereService) {}
  ngOnInit(): void {
    if (this.multiple !== undefined && !this.multiple) {
      this.critereService.querybuilderlist(this.list, '', this.cible, this.rule?.field).subscribe(res => {
        this.fields = res.body.map(val => {
          val.value = val.name + ':' + val.value;
          return val;
        });
        this.init(this.fields);
      });
    }
    this.init(this.fields);
  }

  init(fields): void {
    if (this.fields) {
      this.filteredfields = this.sortFields(fields);

      this.filteredfields.forEach(element => {
        if (element.value === this.ngModel) this.value = element.name.substring(element.name.indexOf('_') + 1);
      });
      // if (this.ngModel == '-' || this.ngModel == 0) {
      //   this.ngModel = this.filteredfields[30].value;
      // }
    }
  }

  ngOnChanges(): void {
    if (
      (this.fields && !this.filteredfields) ||
      this.filteredfields.length === 0 ||
      this.filteredfields[0].entity !== this.fields[0].entity
    ) {
      this.filteredfields = this.sortFields(this.fields);
    }
  }

  sortFields(fields) {
    return this.fields.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 1;
    });
  }
  onChange($event, rule): void {
    this.ngModelChange.emit($event);
  }

  getlabel(type: string): string {
    return type.substring(type.indexOf('_') + 1);
  }

  getvalue(type: string): string {
    return type.substring(0, type.indexOf('_'));
  }

  compareWith(t1: string, t2: string): boolean {
    return t1 === t2;
  }
}
