import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { EntityMap, Field, FieldMap, QueryBuilderComponent, QueryBuilderConfig, Rule, RuleSet, Option } from 'angular2-query-builder';
import { ICritere } from 'app/shared/model/critere.model';
import { CritereService } from 'app/shared/services/critere.service';
import { FormControl } from '@angular/forms';

import * as _moment from 'moment';
import { DatePickerComponent } from './date-picker/date-picker.component';
type EntityArrayResponseTypeOption = HttpResponse<Option[]>;
import { Observable } from 'rxjs';
import { I } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-querybuilders',
  templateUrl: './query-builder.component.html',
  styleUrls: ['./query-builder.component.scss'],
})
export class QueryComponent {
  iscompute = false;
  @Input() cible: number;
  @Input() critere: any;
  @Input() needupdate: boolean;
  @Input() val: RuleSet;
  @Input() groupeCode: any;
  @Input() disabled: boolean;
  @Output() eventChange: EventEmitter<any> = new EventEmitter<string>();
  @Output() updatevolume: EventEmitter<any> = new EventEmitter<string>();
  @ViewChild(QueryBuilderComponent, { static: true }) queryBuilder: QueryBuilderComponent;

  filteredOptions: Observable<EntityArrayResponseTypeOption>;

  defaultConfig: QueryBuilderConfig = {
    fields: {},
    entities: {},
  };

  currentConfig: QueryBuilderConfig = {
    fields: {},
    entities: {},
  };

  config: QueryBuilderConfig = {
    fields: {},
    entities: {},
  };

  allowRuleset = true;
  allowCollapse = true;
  emptyMessage = '';
  persistValueOnFieldChange = true;
  fieldChangeValueStrategy = 'persist';
  allowEmptyRulesets = true;
  lov = {};
  value: RuleSet;
  criteres: ICritere[];
  dialog: any;
  confirmDialogRef: any;
  tablist = [];
  surcharges = [];
  optionCtrl = new FormControl();

  constructor(private critereService: CritereService, private _matDialog: MatDialog, private _adapter: DateAdapter<any>) { }

  majqb(qb: RuleSet, list): void {
    if (this.disabled) {
      this.allowRuleset = false;
      this.allowCollapse = false;
      this.needupdate = false;
      this.disabled = true;
    }
    if(qb && !qb.condition){
      qb.condition = 'and';
    }
    if (!qb) {
      qb = { condition: 'and', rules: [{ field: '0', operator: 'Egal à:=', entity: '0' }] };
    }
    if (list) {
      this.majlist(list);
      this.value = qb;
    }
  }

  majcritere(): void {
    if (!this.value) {
      if (!this.val) {
        this.value = { condition: 'and', rules: [{ field: '0', operator: 'Egal à:=', entity: '0' }] };
      } else {
        this.value = this.val;
      }
    }
    this.majlist(this.critere);
  }
  majiscompute(iscompute: boolean): void {
    this.iscompute = iscompute;
  }

  majlistval(qb: RuleSet): void {
    if (qb.rules) {
      qb.rules.find((x: Rule) => this.maj(x.field));
      qb.rules.find((x: RuleSet) => this.majlistval(x));
    }
  }

  returnvalue(): void {
    if (this.value !== undefined) {
      const res = this.updatevalue(this.value);
      this.eventChange.emit(res);
    }
  }

  updatevalue(val: RuleSet): RuleSet {
    const rules = val.rules;
    const newrules = [];

    if (rules) {
      rules.forEach(r => {
        const rule: any = r as Rule;
        if (Object.prototype.hasOwnProperty.call(r, 'entity') && rule.entity !== null) {
          if (rule.value !== null && typeof rule.value !== 'object') {
            rule.value = [rule.value];
          }
          if (rule.value !== null && rule.value._isAMomentObject) {
            rule.value = [rule.value.format('DD/MM/YYYY')];
          }

          if (rule.value2 !== null && typeof rule.value2 !== 'object') {
            rule.value2 = [rule.value2];
          }
          if (rule.value2 !== null && rule.value2._isAMomentObject) {
            rule.value2 = [rule.value2.format('DD/MM/YYYY')];
          }

          newrules.push(rule);
        } else {
          newrules.push(this.updatevalue(r as RuleSet));
        }
      });
    }

    val.rules = newrules;

    return val;
  }
  getdate(dt): string {
    return dt.format('DD/MM/YYYY');
  }
  onValueChange(): void {
    this.returnvalue();
  }

  majvolume(): void {
    this.returnvalue();
    this.updatevolume.emit(this.updatevalue(this.value));
  }

  onFieldChange(): void {
    this.returnvalue();
  }

  majlist(list): void {
    const entities: EntityMap = {};
    const fields: FieldMap = {};
    const options = [];

    fields[0] = { name: ' ', type: 'string', entity: '-', operators: [':'] };
    entities['-'] = { name: ' ' };
    list.forEach((e: ICritere) => {
      const entity = e.type;
      const identity = e.type;
      const name = e.libelle;

      const type = e.format;
      const liste = e.liste;
      const id = e.id.toString();

      const defaultValue = [];
      const defaultOperator = 'Egal à:=';

      let operators = [
        'Egal à:=',
        'Différent de:!=',
        'Supérieur à:>',
        'Supérieur ou égal à:>=',
        'Inférieur à:<',
        'Est vide:empty',
        "N'est pas vide:notEmpty",
        'Contient:like',
        'Liste:liste',
      ];

      if (!entities[entity]) {
        fields[entity] = { name: ' ', type: 'string', entity, operators };
        entities[entity] = { name: entity.substring(0, entity.indexOf(':')), value: identity };
      }

      switch (e.format) {
        case 'number': {
          operators = [
            'Egal à:=',
            'Différent de:!=',
            'Supérieur à:>',
            'Supérieur ou égal à:>=',
            'Inférieur à:<',
            'Est vide:empty',
            "N'est pas vide:notEmpty",
            'Contient:like',
            'Compris entre:between',
            'Liste:liste',
            ':',
          ];
          break;
        }
        case 'date': {
          operators = [
            'Egal à:=',
            'Différent de:!=',
            'Supérieur à:>',
            'Supérieur ou égal à:>=',
            'Inférieur à:<',
            'Est vide:empty',
            "N'est pas vide:notEmpty",
            'Contient:like',
            'Compris entre:between',
            'Liste:liste',
            ':',
          ];
          break;
        }

        case 'exclusion':
        case 'multiselect':
        case 'list': {
          operators = ['Egal à:=', 'Différent de:!='];
          break;
        }
        default: {
          break;
        }
      }

      operators = [
        'Egal à:=',
        'Différent de:!=',
        'Supérieur à:>',
        'Supérieur ou égal à:>=',
        'Inférieur à:<',
        'Inférieur ou égal à:<=',
        'Est vide:empty',
        "N'est pas vide:notEmpty",
        'Contient:like',
        'Commence par:start',
        'Liste:liste',
      ];

      if (e.format === 'list') {
        operators = [
          'Egal à:=',
          'Différent de:!=',
          'Supérieur à:>',
          'Supérieur ou égal à:>=',
          'Inférieur à:<',
          'Inférieur ou égal à:<=',
          'Est vide:empty',
          "N'est pas vide:notEmpty",
          'Compris entre:between',
          'Contient:like',
          'Liste:liste',
        ];
      }

      if (e.format === 'exclusion') {
        operators = [
          'Egal à:=',
          'Différent de:!=',
          'Supérieur à:>',
          'Supérieur ou égal à:>=',
          'Inférieur à:<',
          'Inférieur ou égal à:<=',
          'Est vide:empty',
          "N'est pas vide:notEmpty",
          'Contient:like',
        ];
      }

      if (e.format === 'date') {
        operators = [
          'Egal à:=',
          'Différent de:!=',
          'Supérieur à:>',
          'Supérieur ou égal à:>=',
          'Inférieur à:<',
          'Inférieur ou égal à:<=',
          'Est vide:empty',
          "N'est pas vide:notEmpty",
          'Contient:like',
          'Compris entre:between',
          'Liste:liste',
        ];
      }

      if (liste) {
        this.lov[id] = liste;
      }
      const obj: Field = { name, type, entity, operators, options, defaultValue, defaultOperator };
      fields[id] = obj;

    });

    // console.log('hello i am entities',entities )
    // console.log('hello i am fields',fields )
    // let sortable = [];
    // for (var obj in fields) {
    //   sortable.push([obj, fields[obj]]);
    //  }
    // sortable.sort(this.compare);
    // console.log('hello i am sorted',sortable )
    // let myobject = sortable.reduce((obj, index) => (obj[0] = obj[1], obj) ,{});


    // console.log('i am the object',myobject)
    // let entitiesSorted = sortable.reduce(
    //   (obj, item) => Object.assign(obj, { [item.key]: item.value }), {});
      // let mapped = sortable.map((item,index) => ({  [index]:item }) );
      // let filedsSorted = Object.assign({}, ...mapped );
      // console.log('i am the last sort',filedsSorted)

    Object.assign(this.config, { entities, fields   });
    this.currentConfig = this.config;

  }
   compare( a, b ) {
    if ( a[1].name < b[1].name ){
      return -1;
    }
    if ( a[1].name > b[1].name ){
      return 1;
    }
    return 0;
  }

  maj(field, search = null): void {
    if (this.lov[field] && this.config.fields[field].type !== 'list') {
      this.critereService.querybuilderlist(this.lov[field], search).subscribe((res: HttpResponse<Option[]>) => {
        this.config.fields[field].options = res.body;
        this.currentConfig = this.config;
        this.refreshField(field);
      });
    }
  }

  onListChange(): void {
    this.returnvalue();
  }

  onChange(): void {
    this.returnvalue();
  }

  private refreshField(field: string): void {
    const srcRule = this.queryBuilder.data.rules.find((x: Rule) => x.field === field) as Rule;
    if (srcRule) {
      const value = srcRule ? srcRule.value : undefined;
      this.queryBuilder.changeField(field, srcRule);
      srcRule.value = value;
    }
  }

  getlabel(type: string): string {
    return type.substring(0, type.indexOf(':'));
  }

  getvalue(type: string): string {
    return type.substring(type.indexOf(':') + 1);
  }

  updatedate(typedate: Date[]): string {
    if (!typedate || typedate.length === 0) {
      return '';
    }

    return _moment(typedate[0]).toISOString();
    // return typedate.map(a => new Date(a).toISOString());
  }

  openDialog(data, field): void {
    const dialogRef = (this.confirmDialogRef = this._matDialog.open(DatePickerComponent, {
      width: '50%',
      data: { value: data.value },
    }));

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (field === 1) {
          data.value = result;
        } else {
          data.value2 = result;
        }
      }
    });
  }

  updateValue($event, rule): void {
    rule.value = $event;
    this.returnvalue();
  }

  getlov(critere): any {
    const list = this.lov[critere];
    if (!this.tablist) {
      this.tablist = [];
    }
    if (this.tablist[list]) {
      return this.tablist[list];
    } else {
      this.tablist[list] = [];
    }

    this.critereService.querybuilderlist(list, '').subscribe(response => {
      this.tablist[list] = response.body;
      return this.tablist[list];
    });
  }

  updatelabel(type: string): string {
    return type.substring(type.indexOf(':') + 1);
  }

  getentities(entities) {
    return entities.slice(0, entities.indexOf(':')).slice(entities.indexOf('_') + 1);
  }

  getfield(field) {
    return field.libelle;
  }


  getcondition(condition) {
    switch (condition) {
      case 'and':
        return 'ET';
      case 'or':
        return 'OU';
      case 'SAUF':
        return 'sauf';
    }
    return '';
  }
  onEvent(event) {
    event.stopPropagation();
  }
}
