<h1 matDialogTitle class="mt-20">Séléction de date</h1>
<div mat-dialog-content class="dialogcontent">
  <div class="row datcalcule">
    Date calculées : <span> {{ datecalcule }}</span>
  </div>
  <div fxLayout="row" fxLayoutGap="20px">
    <div fxFlex="100" class="ml-35 mt-20 mb-20">
      <mat-calendar class="calend" #calendar [(selected)]="selectedDate" (selectedChange)="onSelect($event)"></mat-calendar>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="20px">
    <div fxFlex="100" class="ml-35">
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Jour</mat-label>
        <input matInput placeholder="Jour" [(ngModel)]="d" (input)="updatedate()" />
      </mat-form-field>
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Mois</mat-label>
        <input matInput placeholder="Mois" [(ngModel)]="m" (input)="updatedate()" />
      </mat-form-field>
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Année</mat-label>
        <input matInput placeholder="Année" [(ngModel)]="y" (input)="updatedate()" />
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions class="pt-24 pb-24">
  <button mat-raised-button class="mat-accent mr-16" (click)="valid()">Valider</button>
  <button mat-button (click)="dialogRef.close(false)">Quitter</button>
</div>
