import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { FuseNavigationModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { NavbarVerticalStyle1Component } from 'app/layout/components/navbar/vertical/style-1/style-1.component';
import { PagesModule } from 'app/main/pages/pages.module';
import { ProfileModule } from 'app/main/pages/profile/profile.module';

import { Routes, RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';

const routes: Routes = [];

@NgModule({
  declarations: [NavbarVerticalStyle1Component],
  imports: [
    MatButtonModule,
    MatIconModule,
    FuseSharedModule,
    FuseNavigationModule,
    PagesModule,
    MatTooltipModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [NavbarVerticalStyle1Component],
})
export class NavbarVerticalStyle1Module { }
