import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';

import { FuseSharedModule } from '@fuse/shared.module';

import { ProfileService } from 'app/main/pages/profile/profile.service';
import { ProfileComponent } from 'app/main/pages/profile/profile.component';
import { ProfileTimelineComponent } from 'app/main/pages/profile/tabs/timeline/timeline.component';
import { ProfileAboutComponent } from 'app/main/pages/profile/tabs/about/about.component';
import { ProfilePhotosVideosComponent } from 'app/main/pages/profile/tabs/photos-videos/photos-videos.component';
import { AuthGuard } from 'app/shared/authorization/authguard/authguard.service';
import { SharedModule } from 'app/shared/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UpdateprofilComponent } from './updateprofil/updateprofil.component';
import { ToolbarModule } from 'app/layout/components/toolbar/toolbar.module';

const routes = [
  {
    path: 'profile',
    canActivate: [AuthGuard],
    component: ProfileComponent,
    resolve: {
      profile: ProfileService,
    },
  },
];

@NgModule({
  declarations: [ProfileComponent, ProfileTimelineComponent, ProfileAboutComponent, ProfilePhotosVideosComponent, UpdateprofilComponent],
  imports: [
    RouterModule.forChild(routes),

    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatTabsModule,
    SharedModule,
    MatCheckboxModule,
    FuseSharedModule,
    ToolbarModule,
  ],
  providers: [ProfileService],
})
export class ProfileModule {}
