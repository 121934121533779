import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { AuthorizationService } from 'app/shared/authorization/authorization.service';
import { environment } from 'environments/environment';
import { UtilisateurService } from 'app/shared/services/utilisateur.service';
import { IUtilisateur } from 'app/shared/model/utilisateur.model';
import { MatDialog } from '@angular/material/dialog';
import { UpdateprofilComponent } from './updateprofil/updateprofil.component';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ProfileComponent implements OnInit {
  /**
   *
   * Constructor
   */
  currentUser: any;
  urlimage: string;
  ipn: any;
  dataProfilUser: any;
  defaultAffaire: any;
  ref_adresse: string;
  nomuser: any;
  prenomuser: any;
  data: IUtilisateur;
  isswitcheduser: boolean = true;
  ref_mail: any;
  ref_telephone: any;
  ref_portable: any;
  dataGroupeDto: any;
  code: any;
  email: any;
  service: any;
  profile: any;
  serviceId: any;
  constructor(private authorizationService: AuthorizationService, private apiservice: UtilisateurService, private _matDialog: MatDialog) {
    this.urlimage = environment.apiImg;
  }
  ngOnInit(): void {
    this.authorizationService.infocurrentUser.subscribe(x => {
      this.currentUser = x;
      console.log('currentusrer', this.currentUser);
      this.ipn = this.currentUser.utilisateurDto.ipn;
      this.nomuser = this.currentUser.utilisateurDto.nom;
      this.prenomuser = this.currentUser.utilisateurDto.prenom;
      this.ref_mail = this.currentUser.utilisateurDto.ref_mail;
      this.profile = this.currentUser.profilDto;
      this.serviceId = this.currentUser.serviceDto.id;
      this.ipn = this.currentUser.utilisateurDto.ipn;
      this.ref_telephone = this.currentUser.utilisateurDto.ref_telephone;
      this.ref_portable = this.currentUser.utilisateurDto.ref_portable;
      this.ref_adresse = this.currentUser.utilisateurDto.ref_adresse;
      this.code = this.currentUser.affairepardefaut.code;
      this.email = this.currentUser.utilisateurDto.email;
      this.defaultAffaire = this.currentUser.affairepardefaut.libelle;
      this.data = this.currentUser.utilisateurDto;
    });

    this.authorizationService.currentUser.subscribe(x => {
      (this.currentUser = x), (this.ipn = this.currentUser.uid);
    });

    this.authorizationService.isswitcheduserflag.subscribe(x => {
      this.isswitcheduser = x;
    });
  }

  onImgError(event) {
    event.target.src = 'assets/images/avatars/profile.jpg';
  }

  updateCoordonneUser() {
    const dialogRef = this._matDialog.open(UpdateprofilComponent, {
      data: {
        id: this.data.id,
        ipn: this.data.ipn,
        ref_mail: this.data.ref_mail,
        ref_portable: this.data.ref_portable,
        ref_telephone: this.data.ref_telephone,
        ref_adresse: this.data.ref_adresse,
        sms: this.data.sms,
        nom: this.data.nom,
        prenom: this.data.prenom,
        email: this.data.email,
        courrier: this.data.courrier,
        profile: this.profile,
        drs: this.data.drs,
        notification: this.data.notification,
        derniere_connexion: this.data.derniere_connexion,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  updateUser() {
    this.data = {
      id: this.data.id,
      ipn: this.data.ipn,
      ref_mail: this.data.ref_mail,
      ref_portable: this.data.ref_portable,
      ref_telephone: this.data.ref_telephone,
      ref_adresse: this.data.ref_adresse,
      sms: this.data.sms,
      nom: this.data.nom,
      prenom: this.data.prenom,
      email: this.data.email,
      courrier: this.data.courrier,
      profile: this.profile,
      drs: this.data.drs,
      notification: this.data.notification,
      derniere_connexion: this.data.derniere_connexion,
    };

    this.apiservice.update(this.data).subscribe(result => {
      this.apiservice.AddServiceToUser(this.data.id, this.serviceId).subscribe(res => {});
    });
  }
}
