import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { HeaderService } from 'app/shared/services/header.service';
import { Observable } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { CibleCanal, ICibleCanal } from 'app/shared/model/cible-canal.model.js';

@Component({
  selector: 'headerform',
  templateUrl: './headerform.component.html',
  styleUrls: ['./headerform.component.scss'],
  animations: fuseAnimations,
})
export class HeaderFormComponent implements OnInit {
  url: string;
  pageTitle = 'Jade';
  icon = 'business';
  buttontype = 'Valider';
  @Input() hidebt: boolean;
  @Input() title: string;
  @Input() disable: boolean;
  @Input() ciblecanals: ICibleCanal[];

  @Output() save: EventEmitter<any> = new EventEmitter();
  pageText: Observable<string>;

  constructor(
    private titleService: Title,
    private router: Router,
    private headerService: HeaderService,
    protected activatedRoute: ActivatedRoute
  ) {
    if (this.activatedRoute.routeConfig.path === 'add') {
      this.buttontype = 'Valider';
    } else {
      if (this.activatedRoute.routeConfig.path === ':id/view') {
        this.buttontype = '';
      }
    }
  }

  ngOnInit(): void {
    this.headerService.setText('');
    this.url = this.router.url;
    this.init();
  }

  saveForm(): void {
    this.save.emit();
  }

  cancel(): void {

    if (this.url && this.url === "/apps/ciblagecentral/" + this.activatedRoute.snapshot.params['id'] + "/edit") {
      this.router.navigate(['/apps/target-module']);
    } else {

      let url = this.activatedRoute.snapshot['_routerState'].url;
      url = url.split('/')[2];
      if (url === 'ciblage') url = 'cible';
      this.router.navigate(['/apps/' + url + '/']);
    }
  }

  private getData(routeSnapshot: ActivatedRouteSnapshot, data: string): string {
    let info: string = routeSnapshot.data && routeSnapshot.data[data] ? routeSnapshot.data[data] : '';
    if (routeSnapshot.firstChild) {
      info = this.getData(routeSnapshot.firstChild, data) || info;
    }
    return info;
  }

  private init(): void {
    const pageTitle = this.getData(this.router.routerState.snapshot.root, 'pageTitle');
    const icon = this.getData(this.router.routerState.snapshot.root, 'icon');
    console.log(pageTitle);
    console.log(this.title);
    if (this.title !== undefined) {
      this.pageTitle = this.title;
    } else {
      if (pageTitle) {
        this.pageTitle = pageTitle;
      }
    }

    if (icon) {
      this.icon = icon;
    }

    this.titleService.setTitle(this.pageTitle.replace(/<[^>]*>/g, ''));

    this.pageText = this.headerService.getText();
  }

  sortCanal(canals: CibleCanal[]): CibleCanal[] {
    if (canals) return canals.sort((a, b) => a.ordre - b.ordre);
    return [];
  }
}
