import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserInfo } from 'angular-oauth2-oidc';
import { AuthorizationService } from 'app/shared/authorization/authorization.service';
import { IUtilisateur } from 'app/shared/model/utilisateur.model';
import { UtilisateurService } from 'app/shared/services/utilisateur.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-userswitch',
  templateUrl: './userswitch.component.html',
  styleUrls: ['./userswitch.component.scss'],
})
export class UserswitchComponent implements OnInit {
  ipn: string;
  filteredListUtilisateur: any;
  isswitcheduser: boolean;
  currentUser: UserInfo;
  dataUsers: IUtilisateur;
  size: 100;
  page: 0;
  search: '';

  searchInput: FormControl;

  userForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<UserswitchComponent>,
    private serviceuser: UtilisateurService,
    private authorizationService: AuthorizationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.searchInput = new FormControl('');
  }

  ngOnInit(): void {
    this.authorizationService.currentUser.subscribe(x => {
      (this.currentUser = x), (this.ipn = this.currentUser.uid);
    });

    this.authorizationService.currentUser.subscribe(x => (this.currentUser = x));
    this.authorizationService.isswitcheduserflag.subscribe(x => {
      this.isswitcheduser = x;
    });
    this.getUsers('');

    this.searchInput.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(event => {
      this.getUsers(event);
    });
  }

  onSelectionUser(event: any): void {
    this.ipn = event.value;
  }
  annuler() {
    this.dialogRef.close(false);
  }
  changeuser() {
    this.authorizationService.switchon(this.ipn, true);
  }

  getUsers(search: string): void {
    this.serviceuser.ListSwitchUser({ search: search.toLowerCase() }).subscribe((res: HttpResponse<IUtilisateur[]>) => {
      this.dataUsers = res.body;
      this.filteredListUtilisateur = this.dataUsers.slice();
    });
  }
}
