<mat-toolbar class="p-0 mat-elevation-z1">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button
        mat-icon-button
        class="navbar-toggle-button"
        *ngIf="!hiddenNavbar && !rightNavbar"
        (click)="toggleSidebarOpen('navbar')"
        fxHide.gt-md
      >
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>

      <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
        <div class="logo ml-16">
          <a routerLink="/apps/dashboards/project"> <img width="190" class="logo-icon" src="assets/images/logos/logo-RN-JADE-DA-bl.svg" /></a>
        </div>
      </div>

      <div class="px-8 px-md-10">
        <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
      </div>

      <div class="switch" *ngIf="!isaffaire">
        <mat-slide-toggle color="primary" title="Changer l'utilisateur" [(ngModel)]="isswitcheduser" (change)="userChanges($event)">
        </mat-slide-toggle>
      </div>
    </div>

    <!-- <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center" class="px-8 px-md-10 stat">
      <div class="stat">
        <mat-icon class="logo-icon mr-8" color="accent"> directions_car</mat-icon> <span>3 154 563</span>

        <mat-icon class="logo-icon ml-20 mr-1" color="accent">person</mat-icon>
        <div class="dropdown">
          <button class="dropbtn"><span>2 547 952</span></button>
          <div class="dropdown-content">
            <a href="#">Clients juridiques: 1 548 600 </a>
            <a href="#">Prospects: 353 342 </a>
            <a href="#">Prospects AAA: 645 710</a>
          </div>
        </div>
      </div>
    </div> -->

    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <!-- <img  class="avatar mr-0 mr-sm-16" class="{{isswitcheduser ? 'switcher-image-on' : 'switcher-image-off'}}" src="{{urlimage}}/avatar/{{currentUser.uid}}.jpg" (error) ="onImgError($event)"> -->
          <img
            class="avatar"
            class="{{ profileclass }}"
            src="https://outlook.office.com/owa/service.svc/s/GetPersonaPhoto?email={{ currentUser.uid }}@renault.com&size=HR64x64"
            onerror="this.src='./assets/images/avatars/profile.jpg';"
          />

          <span class="username mr-12" fxHide fxShow.gt-sm>{{ nom }} {{ prenom }}</span>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        <!-- <button mat-menu-item (click)="getProfil(); Gotoprofile()">
          <mat-icon>account_circle</mat-icon>
          <span>Mon Profil</span>
        </button> -->

        <button (click)="logout()" mat-menu-item class="">
          <mat-icon>exit_to_app</mat-icon>
          <span>Déconnexion</span>
        </button>
      </mat-menu>

      <div class="toolbar-separator"></div>

      <fuse-search-bar (input)="search($event)"></fuse-search-bar>

      <div class="toolbar-separator"></div>

      <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
          <span fxLayout="row" fxLayoutAlign="start center">
            <img class="flag mr-16" [src]="'assets/icons/flags/' + lang.flag + '.png'" />
            <span class="iso">{{ lang.title }}</span>
          </span>
        </button>
      </mat-menu>

      <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

      <button
        mat-icon-button
        fxHide.gt-md
        class="chat-panel-toggle-button"
        (click)="toggleSidebarOpen('chatPanel')"
        aria-label="Toggle chat panel"
      >
        <mat-icon class="secondary-text">chat</mat-icon>
      </button>

      <div class="toolbar-separator" fxHide.gt-md></div>

      <button mat-icon-button class="quick-panel-toggle-button" (click)="toggleSidebarOpen('quickPanel')" aria-label="Toggle quick panel">
        <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
      </button>

      <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

      <button
        mat-icon-button
        class="navbar-toggle-button"
        *ngIf="!hiddenNavbar && rightNavbar"
        (click)="toggleSidebarOpen('navbar')"
        fxHide.gt-md
      >
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
