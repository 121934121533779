import { NgModule } from '@angular/core';

import { Error404Module } from 'app/main/pages/errors/404/error-404.module';
import { ProfileModule } from 'app/main/pages/profile/profile.module';

@NgModule({
  imports: [
    // Errors
    Error404Module,

    // Profile
    ProfileModule,
  ],
  declarations: [],
})
export class PagesModule {}
