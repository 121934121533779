import { ApplicationRef, DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { LayoutModule } from 'app/layout/layout.module';
import { AppRoutingModule } from './app-routing.module';

import { ConfirmService } from 'app/shared/services/confirm-dialog/confirm.service';

import { AuthorizationModule } from 'app/shared/authorization/authorization.module';
import { AuthorizationService } from 'app/shared/authorization/authorization.service';
import { JwtInterceptor } from './shared/authorization/interceptor/jwt.interceptor';
import { AppHttpInterceptor } from './shared/authorization/interceptor/http.interceptor';

import { SharedModule } from './shared/shared.module';
import { RnltErrorsModule } from './shared/error/rnlt-errors.module';
import { OAuthModule } from 'angular-oauth2-oidc';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlFr } from './shared/util/paginator-intl';
import { RnltErrorsComponent } from './shared/error/rnlt-errors.component';
import { MainComponent } from './layout/main/main.component';
import { ToastrModule } from 'ngx-toastr';

import { environment } from '../environments/environment';
import { FakeDbService } from './fake-db/fake-db.service';

@NgModule({
  declarations: [MainComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot(),

    InMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true,
    }),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,

    // Oauth
    SharedModule,
    AuthorizationModule.forRoot(),
    RnltErrorsModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    ConfirmService,
    AuthorizationService,
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlFr },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
  ],

  // bootstrap: [MainComponent],
})
export class AppModule implements DoBootstrap {
  constructor(private authorizationService: AuthorizationService) {}

  ngDoBootstrap(app: ApplicationRef): void {
    if (window.location.pathname !== '/login-callback') {
      if (environment.production) {
        sessionStorage.setItem('currenturl', window.location.pathname);
        window.location.href = '/login/';
        console.log('Aws');
      } else {
        sessionStorage.setItem('currenturl', window.location.pathname);
      }
    }
    this.authorizationService
      .bootstrapAuthService()
      .then(() => {
        app.bootstrap(MainComponent);
      })
      .catch(error => {
        console.error(`[ngDoBootstrap] Problem while authorizationService.bootstrapAuthService(): ${JSON.stringify(error)}`, error);

        app.bootstrap(RnltErrorsComponent);
      });
  }
}
