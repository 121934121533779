import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-modal',
  templateUrl: './dialog-modal.component.html',
  styleUrls: ['./dialog-modal.component.scss'],
})
export class DialogModalComponent implements OnInit {
  title: string;
  subject: string;
  listecommande: any;
  constructor(
    public dialogRef: MatDialogRef<DialogModalComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.title = data.title;
    this.subject = data.subject;
    this.listecommande = data?.listecommande;
  }
  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close(true);
  }
}
export interface DialogData {
  affairesSelectionne: any;
  title: string;
  subject: string;
  listecommande?: any;
  user?: any;
  acteurId?: any;
  acteurToModify?: any;
}
