<mat-form-field class="actif">
  <mat-select [compareWith]="compareWith" selectedIndex=-1 #select [(ngModel)]="ngModel" (ngModelChange)="onChange($event, rule)">
    <mat-select-filter [noResultsMessage]="'Pas de résultat'" *ngIf="select.focused" [placeholder]="'Filtrer'"
      [displayMember]="'name'" [array]="fields" (filteredReturn)="filteredfields = $event"></mat-select-filter>

    <mat-option *ngFor="let entity of filteredfields | orderBy: {property: 'name', direction: '1'}"
      [value]="entity.value">
      {{ getlabel(entity.name) }}
    </mat-option>
  </mat-select>
</mat-form-field>
