import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSharedModule } from '@fuse/shared.module';

import { HeaderFormComponent } from './headerform.component';
import { HeaderService } from 'app/shared/services/header.service';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  providers: [HeaderService],
  declarations: [HeaderFormComponent],
  imports: [RouterModule, MatButtonModule, MatIconModule, MatToolbarModule, FuseSharedModule, MatTooltipModule],
  exports: [HeaderFormComponent],
})
export class HeaderFormModule { }
