export const environment = {
  production: true,
  environment: 'prod',
  hmr: false,
  requireHttps: true,
  loginURL: '/authenticate',
  apiURL: '/jade/api',
  searchURL: '/jade-search/api',
  apiImg: '',
  infoSwitcherUrl: '',
  apvCurrentYear: 2025,
  logo: window["env"]["APPLogo"],

  // IDP conf
  oAuthEnable: true,
  issuer: window["env"]["IDPissuer"],
  loginUrl: window["env"]["IDPLoginUrl"],
  userinfoEndpoint: window["env"]["IDPUserInfo"],
  tokenEndpoint: window["env"]["IDPToken"],
  clientId: window["env"]["IDPclientId"],
  cliensecret: window["env"]["IDPclientSecret"],
  scope: 'arca vectury',
  allowedUrls: ['/authenticate', '/jade', 'jade-search'],
};
