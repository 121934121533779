import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { createRequestOption, SearchWithPagination } from 'app/shared/util/request-util';
import { IAffaire } from 'app/shared/model/affaire.model';

import { environment } from 'environments/environment';
import { IMyCheckUpRecap } from '../model/my-check-up-recap.model';
type EntityResponseType = HttpResponse<IAffaire>;
type EntityArrayResponseType = HttpResponse<IAffaire[]>;

@Injectable({ providedIn: 'root' })
export class AffaireService {
  public resourceUrl = environment.apiURL + '/affaires';
  public resourceSearchUrl = environment.apiURL + '/_search/affaires';

  constructor(protected http: HttpClient) { }
  public getAll = (page, size) => this.http.get(this.resourceUrl + '?page=' + page + '&size=' + size);


  create(affaire: IAffaire): Observable<EntityResponseType> {
    return this.http.post<IAffaire>(this.resourceUrl, affaire, { observe: 'response' }).pipe(map((res: EntityResponseType) => res));
  }

  update(affaire: IAffaire): Observable<EntityResponseType> {
    return this.http
      .put<IAffaire>(`${this.resourceUrl}/${affaire.id}`, affaire, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => res));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IAffaire>(`${this.resourceUrl}/${id}`, { observe: 'response' }).pipe(map((res: EntityResponseType) => res));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IAffaire[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => res));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: SearchWithPagination): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IAffaire[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  DeleteAffaireUser(id1: number, id2: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id1}/utilisateurs/${id2}`, { observe: 'response' });
  }

  getAffaireUser(): Observable<any> {
    return this.http.get(`${this.resourceUrl}/userAffaires`);
  }
  getAffaireUserByGroup(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get(`${this.resourceUrl}/user`, { params: options, observe: 'response' });
  }


  getAffaireUserAllData(id: any): Observable<any> {
    return this.http.get(`${this.resourceUrl}/all/${id}`);
  }

  getUserAffaires(): Observable<EntityArrayResponseType> {
    return this.http.get<IAffaire[]>(this.resourceUrl + '/allAffairesWithChilds', { observe: 'response' });
  }

  createAffaireWithAllData(affaire: any): Observable<any> {
    return this.http.post(environment.apiURL + '/crateaffairesAllData', affaire);
  }

  getAllAffairesWithChilds(): Observable<EntityArrayResponseType> {
    return this.http.get<IAffaire[]>(this.resourceUrl + '/allAffairesWithChilds', { observe: 'response' });
  }
  getAllAffairesWithChildsPaged(req: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IAffaire[]>(this.resourceUrl + '/allAffairesWithChildsPaged', { params: options, observe: 'response' });
  }

  getAllAffairesnivR1(req: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IAffaire[]>(this.resourceUrl + '/allAffairesnivR1', { params: options, observe: 'response' });
  }
  getAllAffaireSearchedNonFermeByUtilisateur(id: any, req?: any) {
    const options = createRequestOption(req);
    return this.http
      .get<IAffaire[]>(this.resourceUrl + '/allAffairesnonfermebyutilisateur' + '/' + id, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => res));
  }
  getAllAffaireSearchedNonFerme(id: any, req?: any) {
    const options = createRequestOption(req);
    return this.http
      .get<IAffaire[]>(this.resourceUrl + '/allAffairesnonferme' + '/' + id, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => res));
  }

  getAllMyCheckUpRecap(code: string, req?: any): Observable<HttpResponse<IMyCheckUpRecap[]>> {

    const options = createRequestOption(req);
    return this.http
      .get<IMyCheckUpRecap[]>(this.resourceUrl + '/' + code + '/my-Check-up-recap', { params: options, observe: 'response' })
      .pipe(map((res: HttpResponse<IMyCheckUpRecap[]>) => res));
  }

  getAllMyCheckUpRecapByAllAffaire(ipn: string, req?: any): Observable<HttpResponse<IMyCheckUpRecap[]>> {
    const options = createRequestOption(req);
    return this.http
      .get<IMyCheckUpRecap[]>(this.resourceUrl + '/' + ipn + '/my-Check-up-recap-all', { params: options, observe: 'response' })
      .pipe(map((res: HttpResponse<IMyCheckUpRecap[]>) => res));
  }
}
