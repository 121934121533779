import { NullVisitor } from '@angular/compiler/src/render3/r3_ast';
import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { AuthorizationService } from 'app/shared/authorization/authorization.service';
import { Affaire, IAffaire } from 'app/shared/model/affaire.model';
import { IMultiAffaireVolume, MultiAffaireVolume } from 'app/shared/model/multiAffaireVolume.model';
import { AffaireService } from 'app/shared/services/affaire.service';
import { DashboardMessagePersonaliseService } from 'app/shared/services/dashboardmessagepersonalise.service';
import { MultiAffaireVolumeService } from 'app/shared/services/multi-affaire-volume.service';
import { UtilisateurService } from 'app/shared/services/utilisateur.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-multi-affaire-volume',
  templateUrl: './multi-affaire-volume.component.html',
  styleUrls: ['./multi-affaire-volume.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiAffaireVolumeComponent implements OnInit {
  currentDate = new Date().toISOString();
  volumes: IMultiAffaireVolume;
  affaires: IAffaire[];
  selectedAffaires: IAffaire[];
  selected: string;
  searchInput: string;
  subscription: Subscription;
  bandeauList: any;
  userAffaireList: any;
  defaultaffaire: IAffaire;
  @ViewChild('select') select: MatSelect;

  constructor(
    private userService: UtilisateurService,
    private multiAffaireVolumeService: MultiAffaireVolumeService,
    private authorizationService: AuthorizationService,
    private dashboardMessagepersonaliseService: DashboardMessagePersonaliseService,
    private affaireService: AffaireService,
    private changeDetector: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initialize();

    //reload component when user is switched and current route is dashboard
    this.subscription = this.authorizationService.isReloadDashboard.subscribe(reload => {
      if (reload && this.router.url === '/apps/dashboards/project') {
        this.initialize();
      }
    });
  }

  initialize() {
    //init variables

    this.volumes = null;
    this.affaires = [];
    this.selectedAffaires = [];
    this.selected = '';
    this.searchInput = '';
    this.defaultaffaire = null;

    let currentUser = JSON.parse(sessionStorage.getItem('switchuserinfo'));
    if (currentUser == null) {
      currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

      this.userAffaireList = currentUser.utilisateurAffaires;

      const aff = this.userAffaireList.filter(a => a.defaultaffaire).filter(a => a.defaultaffaire.nIv == 'R1');
      if (aff.length > 0) {
        this.defaultaffaire = aff[0].affaire;
      }
    }

    this.updatebandeau();
    this.userService.getUserR1Affaires(currentUser.ipn).subscribe(res => {
      this.affaires = res.body;
      this.selectedAffaires = this.affaires;

      if (!this.defaultaffaire) {
        this.selected = this.affaires[0].code;
      }

      this.loadVolumes();
    });
  }
  setDefaultAffaire() {}

  loadVolumes() {
    if (this.selected) {
      this.multiAffaireVolumeService.getAffaireVolumes(this.selected).subscribe(res => {
        this.volumes = res.body;
        this.changeDetector.detectChanges();
        if (this.select) this.select.selectionChange.emit();
      });
    }
  }

  onSelectAffaire(code) {
    if (code) {
      this.searchInput = '';
      this.selectedAffaires = this.affaires;
      this.selected = code.value;
      // this.updatebandeau(this.selected);

      this.loadVolumes();
    }
  }

  searchAffaire() {
    this.selectedAffaires = this.affaires.filter(a => {
      let codeLibelle = a.code + a.libelle;
      return codeLibelle.toLowerCase().includes(this.searchInput.toLocaleLowerCase());
    });
  }
  showMessageBandeau(bandeau: any) {
    console.log('bandeau :', bandeau);
    let showBandeau = false;
    if (bandeau.type_filtre == 'tous') {
      this.userAffaireList.forEach(userAffaire => {
        if (bandeau.niveau == 'R1R2') {
          showBandeau = true;
        } else if (bandeau.niveau == userAffaire.affaire.nIv) {
          showBandeau = true;
        }
      });
    }
    bandeau.affaireList.forEach(affaire => {
      this.userAffaireList.forEach(userAffaire => {
        if (userAffaire.affaire.id == affaire.id) {
          console.log('i am the affaires');
          console.log(userAffaire.affaire);
          console.log(affaire);
          if (bandeau.niveau == 'R1R2') {
            showBandeau = true;
          } else if (bandeau.niveau == userAffaire.affaire.nIv) {
            showBandeau = true;
          }
        }
      });
    });
    console.log('is it true ?', showBandeau);
    return showBandeau;
  }

  updatebandeau(): void {
    this.dashboardMessagepersonaliseService.getAllDashboardMessagesPersonalise().subscribe(res => {
      this.bandeauList = res.body;
      this.changeDetector.detectChanges();
    });
  }
}
