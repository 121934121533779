export const locale = {
  lang: 'fr',
  data: {
    NAV: {
      APPLICATIONS: 'Programlar',
      DASHBOARDS: 'Tableau de board',
      CALENDAR: 'Calendrier',
      CAMPAIGN: 'Scénarisation des campagnes',
      DATA: 'Dictionnaire de données ',
      LOG: 'Accés aux log',
      SEARCH: 'Recherche  avancées',
      NETWORK: 'Mon réseau',
      NETWORK_S1: 'Gestion des affaires',
      NETWORK_S2: 'Gestion des signataires',
      CONFIG: 'Paramétrage',
      ECOMMERCE: 'E-Ticaret',
      ACADEMY: 'Akademi',
      MAIL: {
        TITLE: 'Posta',
        BADGE: '15',
      },
      MAIL_NGRX: {
        TITLE: 'Posta Ngrx',
        BADGE: '13',
      },
      CHAT: 'Sohbet',
      FILE_MANAGER: 'Outils',
      CONTACTS: 'Kişiler',
      TODO: 'Yapılacaklar',
      SCRUMBOARD: 'Proje',
    },
  },
};
