import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { UserInfo } from 'angular-oauth2-oidc';
import { AuthorizationService } from 'app/shared/authorization/authorization.service';
import { environment } from 'environments/environment';
import { UtilisateurService } from 'app/shared/services/utilisateur.service';
import { AnyARecord } from 'dns';

@Component({
  selector: 'navbar-vertical-style-1',
  templateUrl: './style-1.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,

  styleUrls: ['./style-1.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;
  currentUser: UserInfo;
  logo = environment.logo;

  isswitcheduser: boolean = true;

  // Private
  private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
  private _unsubscribeAll: Subject<any>;
  urlimage: any;
  ipn: string;
  dataProfil: any;
  defaultAffaire: string;
  nomuser: any;
  prenomuser: any;
  isaffaire: any;
  profileclass: any = 'switcher-image-off';
  oponed: boolean = false;
  /**
   * Constructor
   *
   * @param _fuseConfigService
   * @param _fuseNavigationService
   * @param _fuseSidebarService
   * @param _router
   */
  constructor(
    private authorizationService: AuthorizationService,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _router: Router,
    private apiservice: UtilisateurService
  ) {
    this.urlimage = environment.apiImg;
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Directive
  @ViewChild(FusePerfectScrollbarDirective, { static: true })
  set directive(theDirective: FusePerfectScrollbarDirective) {
    if (!theDirective) {
      return;
    }

    this._fusePerfectScrollbar = theDirective;

    // Update the scrollbar on collapsable item toggle
    this._fuseNavigationService.onItemCollapseToggled.pipe(delay(500), takeUntil(this._unsubscribeAll)).subscribe(() => {
      this._fusePerfectScrollbar.update();
    });

    // Scroll to the active item position
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        setTimeout(() => {
          this._fusePerfectScrollbar.scrollToElement('navbar .nav-link.active', -120);
        });
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.authorizationService.isswitcheduserflag.subscribe(x => {
      this.isswitcheduser = x;
      this.getProfil();
    });

    this.authorizationService.currentUser.subscribe(x => {
      this.currentUser = x;
    });

    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        if (this._fuseSidebarService.getSidebar('navbar')) {
          this._fuseSidebarService.getSidebar('navbar').close();
        }
      });

    // Subscribe to the config changes
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.fuseConfig = config;
    });

    // Get current navigation
    this._fuseNavigationService.onNavigationChanged
      .pipe(
        filter(value => value !== null),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        this.navigation = this._fuseNavigationService.getCurrentNavigation();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar opened status
   */
  toggleSidebarOpened(): void {
    this._fuseSidebarService.getSidebar('navbar').toggleOpen();
  }

  /**
   * Toggle sidebar folded status
   */
  toggleSidebarFolded(): void {
    this._fuseSidebarService.getSidebar('navbar').toggleFold();
  }

  getProfil() {
    this.apiservice.createUserProfil().subscribe((res: any) => {
      //  this.authorizationService.infocurrentUser.next(res.body);
      this.defaultAffaire = res.body.affairepardefaut.libelle;
      this.ipn = res.body.utilisateurDto.ipn;
      this.nomuser = res.body.utilisateurDto.nom;
      this.prenomuser = res.body.utilisateurDto.prenom;
      this.isaffaire = res.body.serviceDto.groupecode === 'RZO' ? true : false;
      this.profileclass = this.isswitcheduser
        ? res.body.serviceDto.groupecode === 'RZO'
          ? 'switcher-image-on-aff'
          : 'switcher-image-on'
        : 'switcher-image-off';
    });
  }
}
