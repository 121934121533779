import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { UserInfo } from 'angular-oauth2-oidc';

import { fuseAnimations } from '@fuse/animations';
import { AuthorizationService } from 'app/shared/authorization/authorization.service';
import { UtilisateurService } from 'app/shared/services/utilisateur.service';
import { IUtilisateur, Utilisateur } from 'app/shared/model/utilisateur.model';
import { MatDialog } from '@angular/material/dialog';
import { UpdateprofilComponent } from '../../updateprofil/updateprofil.component';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'profile-about',
  templateUrl: './about.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./about.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ProfileAboutComponent implements OnInit {
  @Output() dataprofiluser: EventEmitter<any> = new EventEmitter();

  about: any;
  currentUser: UserInfo;
  ipn: string;
  data: Utilisateur;
  idUser: any;
  adressePostal: any;
  email: any;
  ref_mail: any;
  ref_portable: any;
  ref_telephone: any;
  ref_adresse: any;
  emailValue: any;
  courrierValue: any;
  smsValue: any;
  dataProfil: any;
  dataGroupeDto: any;
  nomuser: any;
  prenomuser: any;
  isswitcheduser: boolean = true;

  /**
   * Constructor
   *
   * @param _profileService
   */
  constructor(
    private authorizationService: AuthorizationService,
    private apiService: UtilisateurService,

    private _matDialog: MatDialog,
    private fb: FormBuilder
  ) {
    this.dataprofiluser = new EventEmitter<any>();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.authorizationService.currentUser.subscribe(x => {
      this.currentUser = x;
    });
    this.authorizationService.isswitcheduserflag.subscribe(x => {
      this.isswitcheduser = x;
      this.getProfil();
    });

    this.getProfil();
  }

  updateCoordonneUser() {
    const dialogRef = this._matDialog.open(UpdateprofilComponent, {
      data: {
        id: this.data.id,
        ipn: this.data.ipn,
        ref_mail: this.data.ref_mail,
        ref_portable: this.data.ref_portable,
        ref_telephone: this.data.ref_telephone,
        ref_adresse: this.data.ref_adresse,
        sms: this.data.sms,
        nom: this.data.nom,
        prenom: this.data.prenom,
        email: this.data.email,
        courrier: this.data.courrier,
        groupe: this.dataGroupeDto,
        drs: this.data.drs,
        notification: this.data.notification,
        derniere_connexion: this.data.derniere_connexion,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getProfil();
    });
  }

  getProfil() {
    this.apiService.createUserProfil().subscribe((res: any) => {
      this.data = res.body.utilisateurDto;
      this.ref_mail = res.body.utilisateurDto.ref_mail;
      this.ref_portable = res.body.utilisateurDto.ref_portable;
      this.ref_telephone = res.body.utilisateurDto.ref_telephone;
      this.ref_adresse = res.body.utilisateurDto.ref_adresse;
      this.ipn = res.body.utilisateurDto.ipn;
      this.nomuser = res.body.utilisateurDto.nom;
      this.prenomuser = res.body.utilisateurDto.prenom;
      this.dataGroupeDto = res.body.groupeDto;
    });
  }
}
