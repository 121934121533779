<div id="profile" class="page-layout simple tabbed">
  <!-- HEADER -->
  <div class="header p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end">
    <div class="user-info" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">
      <img
        *ngIf="currentUser"
        class="profile-image avatar huge"
        src="{{ urlimage }}/avatar/{{ currentUser.uid }}.jpg"
        [@animate]="{ value: '*', params: { delay: '50ms', scale: '0.2' } }"
        (error)="onImgError($event)"
      />
      <div *ngIf="currentUser" class="name" [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }">
        {{ nomuser }} {{ prenomuser }}
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content">
    <mat-tab-group *ngIf="currentUser" dynamicHeight="true">
      <mat-tab label="Informations générales">
        <div id="about" class="p-24" fxLayout="row wrap">
          <div
            class="about-content"
            class="row space"
            fxLayout="row"
            fxLayoutGap="20px"
            fusePerfectScrollbar
            fxFlex.gt-sm="50"
            fxFlex.gt-md="100"
          >
            <div fxFlex="50">
              <div class="profile-box info-box general" fxLayout="column">
                <div class="accent">
                  <div class="title">Informations générales</div>
                </div>

                <div class="content">
                  <div class="info-line">
                    <div class="title">Civilité :</div>
                    <div class="info"></div>
                  </div>

                  <div class="info-line">
                    <div class="title">Nom :</div>
                    <div class="info">{{ nomuser }}</div>
                  </div>

                  <div class="info-line">
                    <div class="title">Prénom :</div>
                    <div class="info location" fxLayout="row" fxLayoutAlign="start center">
                      {{ prenomuser }}
                    </div>
                  </div>

                  <div class="info-line">
                    <div class="title">Fonction :</div>
                    <div class="info"></div>
                  </div>

                  <div class="info-line">
                    <div class="title">IPN :</div>
                    <div class="info">{{ ipn }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div fxFlex="50">
              <div class="profile-box info-box general" fxLayout="column">
                <div class="accent">
                  <div class="title">Coordonnées pour correspondance</div>
                </div>

                <div class="content">
                  <div class="info-line">
                    <div class="title">Adresse mail :</div>
                    <div class="info">{{ ref_mail }}</div>
                  </div>

                  <div class="info-line">
                    <div class="title">Tél fixe :</div>
                    <div class="info">{{ ref_telephone }}</div>
                  </div>

                  <div class="info-line">
                    <div class="title">Tél portable :</div>
                    <div class="info location" fxLayout="row" fxLayoutAlign="start center">
                      {{ ref_portable }}
                    </div>
                  </div>

                  <div class="info-line">
                    <div class="title">Adresse postale :</div>
                    <div class="info">{{ ref_adresse }}</div>
                  </div>

                  <div class="actions" fxLayout="row" fxLayoutAlign="end center" [@animate]="{ value: '*', params: { delay: '200ms' } }">
                    <button mat-raised-button color="accent" aria-label="Follow" (click)="updateCoordonneUser()">Modifier</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Mes affaires de rattachement ">
        <div id="about" class="p-24" fxLayout="row wrap">
          <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="50">
            <div class="profile-box info-box general" fxLayout="column">
              <div class="accent">
                <div class="title">Affaires de rattachement</div>
              </div>

              <div class="content">
                <div class="info-line">
                  <div class="title">Code RRF :</div>
                  <div class="info">{{ code }}</div>
                </div>

                <div class="info-line">
                  <div class="title">Nom commercial de l'affaire :</div>
                  <div class="info">{{ defaultAffaire }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Adhésions aux communications ">
        <div id="about" class="p-24" fxLayout="row wrap">
          <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="50">
            <div class="profile-box info-box general" fxLayout="column">
              <div class="accent">
                <div class="title">Adhésions aux communications</div>
              </div>

              <div class="content">
                <div class="info-line">
                  <div class="title">Je souhaite être destinataire des communications SCOPE_DCF</div>

                  <div class="title">
                    Par mail : &nbsp; &nbsp;
                    <div class="form-group">
                      <mat-radio-group class="form-control" class="example-radio-group" [(ngModel)]="data.email">
                        <mat-radio-button [value]="true">Oui</mat-radio-button> &nbsp;
                        <mat-radio-button [value]="false">Non</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>

                  <div class="title">
                    Par SMS : &nbsp;&nbsp;
                    <div class="form-group">
                      <mat-radio-group class="form-control" class="example-radio-group" [(ngModel)]="data.sms">
                        <mat-radio-button [value]="true">Oui</mat-radio-button> &nbsp;
                        <mat-radio-button [value]="false">Non</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>

                  <div class="title">
                    Par Courrier : &nbsp;&nbsp;
                    <div class="form-group">
                      <mat-radio-group class="form-control" class="example-radio-group" [(ngModel)]="data.courrier">
                        <mat-radio-button [value]="true">Oui</mat-radio-button> &nbsp;
                        <mat-radio-button [value]="false">Non</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>

                <div class="actions" fxLayout="row" fxLayoutAlign="end center" [@animate]="{ value: '*', params: { delay: '200ms' } }">
                  <button mat-raised-button color="accent" aria-label="Follow" (click)="updateUser()">Valider</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <!-- / CONTENT -->
</div>
