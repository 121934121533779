import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { OAuthService, UserInfo } from 'angular-oauth2-oidc';
import { AuthorizationService } from '../authorization.service';
import { RnltErrorService } from '../../error/rnlt.error.service';
import { FORBIDDEN, SESSION_TIME_OUT, UNAUTHORIZED } from '../../error/rnlt.error.model';
import { EnvironmentService } from '../../environment/environment.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private oAuthService: OAuthService,
    private authorizationService: AuthorizationService,
    private rnltErrorService: RnltErrorService,
    private envirenementService: EnvironmentService
  ) {}

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.envirenementService.isOAuthEnabled()) {
      return true;
    }

    // 1- if no token return false
    if (!this.oAuthService.getAccessToken()) {
      return false;
    }

    // 2- Check if the token has been expired
    if (this.rnltErrorService.isTokenExpired()) {
      this.rnltErrorService.redirect(SESSION_TIME_OUT);
      this.authorizationService.logout();
      return false;
    }

    // 3- Validate the token && Check Identity
    if (!this.oAuthService.hasValidAccessToken()) {
      this.rnltErrorService.redirect(UNAUTHORIZED);
      return false;
    }

    const userInfo: UserInfo = this.authorizationService.getUserInfo();
    // 4- Check the user type
    if (!userInfo) {
      this.rnltErrorService.redirect(FORBIDDEN);
      return false;
    }

    // 5- Check the user authorization
    if (!AuthorizationService.isAllowed(userInfo.personType, activatedRouteSnapshot.data['authorizations'])) {
      console.log('[AuthGuard]: user type', userInfo.personType);
      this.rnltErrorService.redirect(FORBIDDEN);
      return false;
    }

    return true;
  }
}
