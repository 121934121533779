// Exception text
export const TOKEN_EXPIRED = 'Token expired';
export const DB_GENERIC_EXCEPTION = 'org.hibernate.exception.GenericJDBCException';
// Http Errors
export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const SESSION_TIME_OUT = 490;
export const USER_NOT_FOUND = 491;
// Messages
export const DEFAULT_MESSAGE = 'Error while calling the server';
export const DB_EXCEPTION_MESSAGE = 'Data Base exception';

export interface RnltErrorMessage {
  titre: string;
  message: string;
  buttonLabel: string;
  buttonLink: string;
}

export interface RnltErrorMessageType {
  [messageNumber: number]: {
    titre: string;
    message: string;
    buttonLabel: string;
    buttonLink: string;
  };
}

export const rnltErrorMessages: RnltErrorMessageType = {
  401: {
    titre: 'IPN Error',
    message: 'SORRY ! YOUR ARE NOT ALLOWED TO REACH THIS PAGE.\nTHANK FOR YOUR VISIT.',
    buttonLabel: 'Back to dashboard page',
    buttonLink: '/',
  },
  403: {
    titre: 'Forbidden',
    message: 'SORRY ! YOUR ARE NOT ALLOWED TO REACH THIS PAGE.\nTHANK FOR YOUR VISIT.',
    buttonLabel: 'Back to dashboard page',
    buttonLink: '/',
  },
  404: {
    titre: "URL DOESN'T EXIST.",
    message: "SORRY ! THIS URL DOESN'T EXIST.\nTHANK YOU FOR YOUR VISIT.",
    buttonLabel: 'Back to dashboard page',
    buttonLink: '/',
  },
  490: {
    titre: 'Session Timeout',
    message: 'SORRY ! YOUR SESSION IS CLOSED.\nPLEASE RE-CONNECT.',
    buttonLabel: 'Back to login page',
    buttonLink: '/',
  },
  491: {
    titre: 'User Not Found',
    message: 'SORRY ! The user is not found.\nPLEASE RE-CONNECT.',
    buttonLabel: 'Back to login page',
    buttonLink: '/',
  },
};
