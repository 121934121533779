<div id="about" class="p-24" fxLayout="row wrap">
  <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="50">
    <div class="profile-box info-box general" fxLayout="column">
      <div class="accent">
        <div class="title">Adhésions aux communications</div>
      </div>

      <div class="content">
        <div class="info-line">
          <div class="title">Je souhaite être destinataire des communications SCOPE_DCF</div>

          <div class="title">
            Par mail : &nbsp; &nbsp;
            <div class="form-group">
              <mat-radio-group class="example-radio-group" [(ngModel)]="data.email">
                <mat-radio-button [value]="true">Oui</mat-radio-button> &nbsp;
                <mat-radio-button [value]="false">Non</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div class="title">
            Par SMS : &nbsp;&nbsp;
            <div class="form-group">
              <mat-radio-group class="example-radio-group" [(ngModel)]="data.sms">
                <mat-radio-button [value]="true">Oui</mat-radio-button> &nbsp;
                <mat-radio-button [value]="false">Non</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div class="title">
            Par Courrier : &nbsp;&nbsp;
            <div class="form-group">
              <mat-radio-group class="example-radio-group" [(ngModel)]="data.courrier">
                <mat-radio-button [value]="true">Oui</mat-radio-button> &nbsp;
                <mat-radio-button [value]="false">Non</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>

        <div class="actions" fxLayout="row" fxLayoutAlign="end center" [@animate]="{ value: '*', params: { delay: '200ms' } }">
          <button mat-raised-button color="accent" aria-label="Follow" (click)="updateUser()">Valider</button>
        </div>
      </div>
    </div>
  </div>
</div>
