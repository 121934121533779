import { NgModule } from '@angular/core';

import { VerticalLayout1Module } from 'app/layout/vertical/layout-1/layout-1.module';

import { HomeModule } from 'app/layout/home/home.module';
import { QBuilderModule } from './components/query-builder/query-builder.module';

@NgModule({
  imports: [VerticalLayout1Module, HomeModule, QBuilderModule],
  exports: [VerticalLayout1Module, HomeModule, QBuilderModule],
})
export class LayoutModule {}
