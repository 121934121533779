import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class RnltErrorService {
  constructor(private router: Router, private oAuthService: OAuthService) {}

  redirect(errorCode) {
    this.router.navigateByUrl('/error/' + errorCode);
  }

  isTokenExpired() {
    const currentDate = new Date().getTime();
    const tokenExpirationDate = this.oAuthService.getAccessTokenExpiration();
    return tokenExpirationDate && currentDate > this.oAuthService.getAccessTokenExpiration();
  }
}
