<query-builder [ngModel]="value" [config]="currentConfig" [allowRuleset]="allowRuleset" [emptyMessage]="emptyMessage"
  [allowCollapse]="allowCollapse" [persistValueOnFieldChange]="persistValueOnFieldChange" (onChange)="onChange()" #que
  class="qb">
  <ng-container
    *queryButtonGroup="let ruleset; let addRule = addRule; let addRuleSet = addRuleSet; let removeRuleSet = removeRuleSet;">
    <button type="button" mat-icon-button color="primary" (click)="majvolume()"
      *ngIf="!disabled && !removeRuleSet && needupdate" [disabled]="iscompute">
      <mat-icon matTooltip="Lancer le calcul">refresh</mat-icon>
    </button>
    <button type="button" mat-icon-button color="primary" *ngIf="!disabled" (click)="addRule(); onChange()">
      <mat-icon matTooltip="Ajouter un critère">add</mat-icon>
    </button>
    <button type="button" mat-icon-button color="primary" *ngIf="!disabled && addRuleSet"
      (click)="addRuleSet(); onChange()">
      <mat-icon matTooltip="Ajouter un bloc">add_circle_outline</mat-icon>
    </button>
    <button type="button" mat-icon-button color="accent" *ngIf="!disabled && removeRuleSet"
      (click)="removeRuleSet(); onChange()">
      <mat-icon>remove_circle_outline</mat-icon>
    </button>
  </ng-container>

  <ng-container *queryArrowIcon>
    <mat-icon ngClass="mat-arrow-icon">chevron_right</mat-icon>
  </ng-container>

  <ng-container *queryRemoveButton="let rule; let removeRule = removeRule" fxFlex="10">
    <button type="button" *ngIf="!disabled" mat-icon-button color="accent" (click)="removeRule(rule); onChange()"
      class="remove-rule-btn">
      <mat-icon>remove</mat-icon>
    </button>
  </ng-container>

  <ng-container *querySwitchGroup="let ruleset; let onChange = onChange">
    <ng-template [ngIf]="disabled">
      <input type="text" readonly="readonly" [ngModel]="getcondition(ruleset.condition)" />
    </ng-template>
    <ng-template [ngIf]="!disabled">
      <mat-radio-group *ngIf="ruleset" [(ngModel)]="ruleset.condition" (ngModelChange)="onChange($event)">
        <mat-radio-button [disabled]="disabled" [style.padding.px]="10" value="and">ET</mat-radio-button>
        <mat-radio-button [disabled]="disabled" [style.padding.px]="10" value="or">OU</mat-radio-button>
        <mat-radio-button [disabled]="disabled" [style.padding.px]="10" value="sauf" *ngIf="groupeCode !== 'RZO'">SAUF
        </mat-radio-button>
      </mat-radio-group>
      <!-- <span *ngIf="!disabled"><input [(ngModel)]="ruleset.condition"/> </span> -->
    </ng-template>
  </ng-container>

  <ng-container *queryEntity="let rule; let entities = entities; let onChange = onChange" fxFlex="15">
    <ng-template [ngIf]="disabled">
      <mat-form-field appearance="none">
        <input matInput ngDefaultControl type="text" readonly="readonly" [ngModel]="getentities(rule.entity)" />
      </mat-form-field>
    </ng-template>
    <ng-template [ngIf]="!disabled">
      <app-querybuilders-select-filter [(ngModel)]="rule.entity" (ngModelChange)="onChange($event, rule)"
        [fields]="entities" [rule]="rule" [type]="'entity'">
      </app-querybuilders-select-filter>
    </ng-template>
  </ng-container>

  <ng-container *queryField="let rule; let fields = fields; let onChange = onChange; let getFields = getFields"
    fxFlex="15">
    <ng-template [ngIf]="disabled">
      <mat-form-field appearance="none">
        <input matInput ngDefaultControl type="text" readonly="readonly" [ngModel]="getfield(rule)" />
      </mat-form-field>
    </ng-template>
    <ng-template [ngIf]="!disabled">
      <app-querybuilders-select-filter [disabled]="disabled" [(ngModel)]="rule.field"
        (ngModelChange)="onFieldChange(); onChange($event, rule)" [fields]="getFields(rule.entity)" [rule]="rule"
        [type]="'field'">
      </app-querybuilders-select-filter>
    </ng-template>
  </ng-container>

  <ng-container *queryOperator="let rule; let operators = operators; let onChange = onChange" class="operators"
    fxFlex="8">
    <ng-template [ngIf]="disabled">
      <mat-form-field appearance="none">
        <input matInput ngDefaultControl type="text" readonly="readonly" [ngModel]="getlabel(rule.operator)" />
      </mat-form-field>
    </ng-template>
    <ng-template [ngIf]="!disabled">
      <mat-form-field>
        <mat-select [disabled]="disabled" [(ngModel)]="rule.operator" (ngModelChange)="onChange($event)">
          <mat-option *ngFor="let value of operators" [value]="value">
            {{ getlabel(value) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>

  </ng-container>

  <ng-container *queryInput="let rule; type: 'boolean'; let onChange = onChange" fxFlex="40">

    <ng-container *ngIf="rule.operator === 'Liste:liste'">
      <app-querybuilders-list [rule]="rule" [multiple]="true" [cible]="cible" [disabled]="disabled" class="list"
        [(ngModel)]="rule.value" (ngModelChange)="onListChange()"
        [list]="'ListeAffaire_import'"></app-querybuilders-list>
    </ng-container>
    <ng-container *ngIf="rule.operator !== 'Liste:liste'">

      <ng-container *ngIf="rule.operator !== 'Est vide:empty' && rule.operator !== 'N\'est pas vide:notEmpty'">
        <mat-checkbox [(ngModel)]="rule.value" (ngModelChange)="onChange($event, rule)"> </mat-checkbox>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *queryInput="let rule; type: 'date'; let onChange = onChange" fxFlex="40">
    <ng-container *ngIf="rule.operator === 'Liste:liste'">
      <app-querybuilders-list [rule]="rule" [multiple]="true" [cible]="cible" [disabled]="disabled" class="list"
        [(ngModel)]="rule.value" (ngModelChange)="onListChange()"
        [list]="'ListeAffaire_import'"></app-querybuilders-list>
    </ng-container>
    <ng-container *ngIf="rule.operator !== 'Liste:liste'">
      <ng-container *ngIf="rule.operator !== 'Est vide:empty' && rule.operator !== 'N\'est pas vide:notEmpty'">
        <ng-container *ngIf="rule.operator === 'Compris entre:between'">
          <mat-form-field>
            <input [disabled]="disabled" autocomplete="off" matInput [(ngModel)]="rule.value"
              (ngModelChange)="onChange($event, rule)" />
            <mat-icon matSuffix (click)="openDialog(rule, 1)">calendar_today</mat-icon>
          </mat-form-field>
          et
          <mat-form-field>
            <input [disabled]="disabled" autocomplete="off" matInput [(ngModel)]="rule.value2"
              (ngModelChange)="onChange($event, rule)" />
            <mat-icon matSuffix (click)="openDialog(rule, 2)">calendar_today</mat-icon>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="rule.operator !== 'Compris entre:between'">
          <mat-form-field>
            <input [disabled]="disabled" autocomplete="off" matInput [(ngModel)]="rule.value"
              (ngModelChange)="onChange($event, rule)" />
            <mat-icon matSuffix (click)="openDialog(rule, 1)">calendar_today</mat-icon>
          </mat-form-field>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>



  <ng-container *queryInput="let rule; let options = options; type: 'multiselect'; let onChange = onChange">
    <mat-form-field [style.width.px]="300">

      <app-querybuilders-list [rule]="rule" [multiple]="true" [cible]="cible" [disabled]="disabled" class="list"
        [(ngModel)]="rule.value" (ngModelChange)="onListChange()"
        [list]="'ListeAffaire_import'"></app-querybuilders-list>

      <ng-container *ngIf="rule.operator !== 'Liste:liste'">
        <mat-select [(ngModel)]="rule.value" multiple (ngModelChange)="onChange($event)">
          <mat-option *ngFor="let opt of getlov(rule.field)" [value]="opt.value">
            {{ opt.name }}
          </mat-option>
        </mat-select>
      </ng-container>

    </mat-form-field>
  </ng-container>


  <ng-container *queryInput="let rule; let options = options; type: 'exclusion'" fxFlex="40">

    <ng-container *ngIf="rule.operator === 'Liste:liste'">
      <app-querybuilders-list [rule]="rule" [multiple]="true" [cible]="cible" [disabled]="disabled" class="list"
        [(ngModel)]="rule.value" (ngModelChange)="onListChange()"
        [list]="'ListeAffaire_import'"></app-querybuilders-list>
    </ng-container>
    <ng-container
      *ngIf="rule.operator !== 'Liste:liste' && rule.operator !== 'Est vide:empty' && rule.operator !== 'N\'est pas vide:notEmpty'">
      <app-querybuilders-list [rule]="rule" [multiple]="true" [cible]="cible" [disabled]="disabled" class="list"
        [(ngModel)]="rule.value" (ngModelChange)="onListChange()" [list]="lov[rule.field]">
      </app-querybuilders-list>
    </ng-container>

  </ng-container>

  <ng-container *queryInput="let rule; let options = options; type: 'list'" fxFlex="40">
    <ng-template [ngIf]="disabled">
      <mat-form-field class="qb-list-disable">
        <mat-chip-list #chipList class="chip-list">
          <mat-chip *ngFor="let option of rule.value" (value)="(option)">
            {{ updatelabel(option) }}

          </mat-chip>
          <input />
        </mat-chip-list>

      </mat-form-field>
    </ng-template>

    <ng-template [ngIf]="!disabled">

      <ng-container *ngIf="rule.operator === 'Compris entre:between'" class="between">


        <app-querybuilders-list [rule]="rule" [multiple]="false" [cible]="cible" [disabled]="disabled" class="listbetween"
          [(ngModel)]="rule.value" (ngModelChange)="onListChange()" [list]="lov[rule.field]"></app-querybuilders-list>

        et

        <app-querybuilders-list [rule]="rule" [multiple]="false" [cible]="cible" [disabled]="disabled" class="listbetween"
          [(ngModel)]="rule.value2" (ngModelChange)="onListChange()" [list]="lov[rule.field]"></app-querybuilders-list>


      </ng-container>
      <ng-container *ngIf="rule.operator === 'Liste:liste'">
        <app-querybuilders-list [rule]="rule" [multiple]="true" [cible]="cible" [disabled]="disabled" class="list"
          [(ngModel)]="rule.value" (ngModelChange)="onListChange()"
          [list]="'ListeAffaire_import'"></app-querybuilders-list>
      </ng-container>
      <ng-container
        *ngIf="rule.operator !== 'Liste:liste' && rule.operator !== 'Est vide:empty' && rule.operator !== 'N\'est pas vide:notEmpty' && rule.operator !== 'Compris entre:between'">
        <app-querybuilders-list [rule]="rule" [multiple]="true" [cible]="cible" [disabled]="disabled" class="list"
          [(ngModel)]="rule.value" (ngModelChange)="onListChange()" [list]="lov[rule.field]">
        </app-querybuilders-list>
      </ng-container>
    </ng-template>
  </ng-container>

  <ng-container *queryInput="let rule; let field = field; type: 'number'; let onChange = onChange" fxFlex="40">

    <ng-container *ngIf="rule.operator === 'Liste:liste'">
      <app-querybuilders-list [rule]="rule" [multiple]="true" [cible]="cible" [disabled]="disabled" class="list"
        [(ngModel)]="rule.value" (ngModelChange)="onListChange()"
        [list]="'ListeAffaire_import'"></app-querybuilders-list>
    </ng-container>
    <ng-container *ngIf="rule.operator !== 'Liste:liste'">
      <ng-container *ngIf="rule.operator !== 'Est vide:empty' && rule.operator !== 'N\'est pas vide:notEmpty'">
        <ng-container *ngIf="rule.operator === 'Compris entre:between'">
          <mat-form-field>
            <input autocomplete="off" matInput [(ngModel)]="rule.value[0]" type="number"
              (ngModelChange)="onChange($event, rule)" />
          </mat-form-field>
          et
          <mat-form-field>
            <input autocomplete="off" matInput [(ngModel)]="rule.value[1]" type="number"
              (ngModelChange)="onChange($event, rule)" />
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="rule.operator !== 'Compris entre:between'">
          <mat-form-field>
            <input autocomplete="off" matInput [(ngModel)]="rule.value" type="number"
              (ngModelChange)="onChange($event, rule)" />
          </mat-form-field>
        </ng-container>
      </ng-container>
    </ng-container>

  </ng-container>

  <ng-container *queryInput="let rule; let field = field; type: 'string'; let onChange = onChange" fxFlex="40">

    <ng-container *ngIf="rule.operator === 'Liste:liste'">
      <app-querybuilders-list [rule]="rule" [multiple]="true" [cible]="cible" [disabled]="disabled" class="list"
        [(ngModel)]="rule.value" (ngModelChange)="onListChange()"
        [list]="'ListeAffaire_import'"></app-querybuilders-list>
    </ng-container>
    <ng-container
      *ngIf="rule.operator !== 'Liste:liste' && rule.operator !== 'Est vide:empty' && rule.operator !== 'N\'est pas vide:notEmpty'">
      <mat-form-field>
        <input autocomplete="off" matInput [(ngModel)]="rule.value" (ngModelChange)="onChange($event, rule)" />
      </mat-form-field>
    </ng-container>

  </ng-container>

  <ng-container *queryInput="let rule; let field = field; type: 'textarea'; let onChange = onChange" fxFlex="40">

    <ng-container *ngIf="rule.operator === 'Liste:liste'">
      <app-querybuilders-list [rule]="rule" [multiple]="true" [cible]="cible" [disabled]="disabled" class="list"
        [(ngModel)]="rule.value" (ngModelChange)="onListChange()"
        [list]="'ListeAffaire_import'"></app-querybuilders-list>
    </ng-container>
    <ng-container
      *ngIf="rule.operator !== 'Liste:liste' && rule.operator !== 'Est vide:empty' && rule.operator !== 'N\'est pas vide:notEmpty'">
      <mat-form-field>
        <textarea matInput [(ngModel)]="rule.value" (ngModelChange)="onChange($event, rule)"> </textarea>
      </mat-form-field>
    </ng-container>

  </ng-container>

</query-builder>