import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IUtilisateur } from 'app/shared/model/utilisateur.model';
import { UtilisateurService } from 'app/shared/services/utilisateur.service';
import { AuthorizationService } from 'app/shared/authorization/authorization.service';

@Component({
  selector: 'app-updateprofil',
  templateUrl: './updateprofil.component.html',
  styleUrls: ['./updateprofil.component.scss'],
})
export class UpdateprofilComponent implements OnInit {
  currentUser: any;
  serviceId: number;

  constructor(
    public dialogRef: MatDialogRef<UpdateprofilComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IUtilisateur,
    private authorizationService: AuthorizationService,
    public dataService: UtilisateurService
  ) {}
  emailFormControl = new FormControl('', [Validators.email]);

  ngOnInit(): void {
    this.authorizationService.infocurrentUser.subscribe(x => {
      this.currentUser = x;
      this.serviceId = this.currentUser.serviceDto.id;
    });
  }

  update() {
    this.data = {
      id: this.data.id,
      ipn: this.data.ipn,
      ref_adresse: this.data.ref_adresse,
      ref_mail: this.data.ref_mail,
      ref_portable: this.data.ref_portable,
      ref_telephone: this.data.ref_telephone,
      sms: this.data.sms,
      nom: this.data.nom,
      prenom: this.data.prenom,
      email: this.data.email,
      courrier: this.data.courrier,
      drs: this.data.drs,
      profile: this.data.profile,
      notification: this.data.notification,
      derniere_connexion: this.data.derniere_connexion,
    };

    this.dataService.update(this.data).subscribe(result => {
      this.dataService.AddServiceToUser(this.data.id, this.serviceId).subscribe(res => {});
    });
    this.onNoClick();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
