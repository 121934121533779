import { LOCALE_ID, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSharedModule } from '@fuse/shared.module';

import { HeaderFormCiblageComponent } from './headerformciblage.component';
import { HeaderService } from 'app/shared/services/header.service';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { MatTooltipModule } from '@angular/material/tooltip';
registerLocaleData(localeFr);

@NgModule({
  providers: [HeaderService, { provide: LOCALE_ID, useValue: 'fr-FR' }],
  declarations: [HeaderFormCiblageComponent],
  imports: [RouterModule, MatButtonModule, MatIconModule, MatToolbarModule, FuseSharedModule, MatTooltipModule],
  exports: [HeaderFormCiblageComponent],
})
export class HeaderFormCiblageModule { }
