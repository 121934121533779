<h1 matDialogTitle>{{ title }}</h1>

<div mat-dialog-content>
  <h3>
    <span [innerHtml]="subject"></span>
  </h3>
  <div *ngIf="listecommande != undefined && listecommande.length > 0" class="ml-25">
    <h2>Liste des commandes</h2>
    <!-- <mat-list ml-5>
            <mat-list-item *ngFor="let commande of listecommande">
              <h5 matLine class="ml-25"> <mat-icon >shopping_cart</mat-icon> {{commande.code}} </h5>

            </mat-list-item> -->
    <!-- </mat-list> -->
  </div>
</div>
<footer fxLayout="row" fxLayoutAlign="space-between center"></footer>
<div mat-dialog-actions class="pt-24" align="end">
  <button mat-raised-button color="accent" class="post-button bb" aria-label="POST" (click)="onNoClick()">OK</button>
</div>
