import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiAffaireVolumeComponent } from './multi-affaire-volume/multi-affaire-volume.component';
import { RouterModule, Routes } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import {MatCardModule} from '@angular/material/card';

const routes: Routes = [
  {
    path: '**',
    component: MultiAffaireVolumeComponent,
  },
];

@NgModule({
  declarations: [
    MultiAffaireVolumeComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    MatCardModule,
    MatSelectModule,
    MatIconModule,
    FormsModule
  ]
})
export class MultiAffaireVolumeModule { }
