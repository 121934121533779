import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LogoutComponent } from './shared/authorization/logout/logout.component';
import { MainComponent } from './layout/main/main.component';
import { MultiAffaireVolumeModule } from './main/apps/dashboards/multi-affaire-volume/multi-affaire-volume.module';
import { AuthGuardMaintenanceService } from './shared/authorization/authguard/auth-guard-maintenance.service';
import { MultiAffaireVolumeComponent } from './main/apps/dashboards/multi-affaire-volume/multi-affaire-volume/multi-affaire-volume.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardMaintenanceService],
    children: [
      { path: '', component: MultiAffaireVolumeComponent },

      {
        path: 'apps',
        loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule)
      }
    ],
    data: {
      allowedProfiles: ['PROFIL-CENTRAL', 'ITADMIN', 'SCOPE-IT', 'SCOPE-MKT', 'SCOPE-RR']
    }
  },
  {
    path: 'page-maintenance',
    loadChildren: () => import('./main/page-maintenance/page-maintenance.module').then(m => m.PageMaintenanceModule)
  },

  { path: 'login-callback', component: MainComponent },

  { path: 'home', component: MultiAffaireVolumeModule },

  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
  },

  { path: 'logout', component: LogoutComponent },

  { path: '**', redirectTo: 'pages/errors/error-404' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false, preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],

})
export class AppRoutingModule { }
