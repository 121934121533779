<div class="app-splash-screen" [ngStyle]="{ left: windowWidth }">
  <div class="app-splash-inner">
    <div class="logo">
      <!-- <img width="128" src="assets/images/logos/logo-small-200.png" /> -->
      <img width="450" src="assets/images/logos/logo-RN-JADE-DA-bl.svg" />
    </div>
    <div class="app-label">Vous êtes déconnectés</div>
    <div (click)="redirectToLogin()" class="app-label">Reconnexion</div>
    <div class="spinner-wrapper">
      <div class="spinner">
        <div class="inner">
          <div class="gap"></div>
          <div class="left">
            <div class="half-circle"></div>
          </div>
          <div class="right">
            <div class="half-circle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
