import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { createRequestOption, Search, SearchWithPagination } from 'app/shared/util/request-util';
import { ICritere } from 'app/shared/model/critere.model';

import { environment } from 'environments/environment';
import { Environnement } from '../model/environnement.model';

import { Option } from 'angular2-query-builder';
import { Liste } from '../model/liste.model';
import { values } from 'lodash';
type EntityResponseType = HttpResponse<ICritere>;
type EntityArrayResponseType = HttpResponse<ICritere[]>;
type EntityArrayResponseTypeString = HttpResponse<string[]>;
type EntityArrayResponseTypeOption = HttpResponse<Option[]>;

export interface OptionSelected {
  name: string;
  value: string;
  selected: boolean;
}
type EntityArrayResponseTypeOptionSelected = HttpResponse<OptionSelected[]>;

@Injectable({ providedIn: 'root' })
export class CritereService {
  public resourceUrl = environment.apiURL + '/criteres';
  public UrlParams = environment.apiURL + '/criteres_params/critere';
  public UrlParamsEnv = environment.apiURL + '/criteres_params/environnement';

  constructor(protected http: HttpClient) {}

  create(critere: ICritere): Observable<EntityResponseType> {
    return this.http.post<ICritere>(this.resourceUrl, critere, { observe: 'response' });
  }

  update(critere: ICritere): Observable<EntityResponseType> {
    return this.http.put<ICritere>(`${this.resourceUrl}`, critere, { observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<ICritere>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<ICritere[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: SearchWithPagination): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<ICritere[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  querybuilderparambyenv(environnement: number): Observable<EntityArrayResponseType> {
    return this.http.get<ICritere[]>(`${this.resourceUrl}/environnements/${environnement}`, { observe: 'response' });
  }

  querybuilderparam(): Observable<EntityArrayResponseType> {
    return this.http.get<ICritere[]>(`${this.resourceUrl}/cible`, { observe: 'response' });
  }

  querybuilderlist(code: string, search: string = '', cible: number = null, critereId : number = null): Observable<EntityArrayResponseTypeOptionSelected> {
    let url = `${this.resourceUrl}/liste/${code}`;

    if (search && search !== ''){
      url = `${url}/${search.toLowerCase()}`;
    }

    url = `${url}?cible=${cible}&critere_id=${critereId}`
    
    return this.http.get<OptionSelected[]>(url, { observe: 'response' });
  }

  querybuilderuserlist(search: string = null): Observable<EntityArrayResponseTypeOption> {
    if (search !== null) {
      return this.http.get<Option[]>(`${this.resourceUrl}/userliste/${search.toLowerCase()}`, { observe: 'response' });
    } else {
      return this.http.get<Option[]>(`${this.resourceUrl}/userliste/`, { observe: 'response' });
    }
  }

  // Relation Critere Groupe
  GetListCritereGroupe(id): Observable<EntityArrayResponseType> {
    return this.http.get<ICritere[]>(`${this.resourceUrl}/${id}/groupes`, { observe: 'response' });
  }

  GetListEnvByCritere(id): Observable<EntityArrayResponseType> {
    return this.http.get<ICritere[]>(`${this.resourceUrl}/${id}/environnements`, { observe: 'response' });
  }
  // Relation Critere Environnement
  public GetListCritereEnv = id => this.http.get(`${this.resourceUrl}/environnements/${id}`);

  //Liste table par critére
  GetTtable(id: number) {
    return this.http.get(`${this.resourceUrl}/${id}/ttables`);
  }

  //Liste Criteres par id table
  GetCritereByTable(id: number) {
    return this.http.get(`${this.resourceUrl}/ttable/surcharge/${id}`);
  }

  getCriteres() {
    return this.http.get(`${this.resourceUrl}/ttable/all`);
  }

  getCriteresas(): Observable<EntityArrayResponseType> {
    return this.http.get<ICritere[]>(`${this.resourceUrl}/sas`, { observe: 'response' });
  }

  AssocierCritereParam(id1, id2, id3) {
    return this.http.post(`${this.resourceUrl}/${id1}/profils/${id2}/environnements/${id3}`, { observe: 'response' });
  }

  DeleteCritereParam(id1, id2, id3) {
    return this.http.delete(`${this.resourceUrl}/${id1}/profils/${id2}/environnements/${id3}`, { observe: 'response' });
  }
  getCritereParams(id) {
    return this.http.get(`${this.UrlParams}/${id}`);
  }

  GetCritereEnv(id) {
    return this.http.get(`${this.UrlParamsEnv}/${id}`);
  }
}
