import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../authorization/authorization.service';

@Component({
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
})
export class TemplateComponent implements OnInit {
  userIpn: string;

  constructor(private authorizationService: AuthorizationService) {}

  async ngOnInit() {
    this.userIpn = this.authorizationService.getUserId();
  }
}
