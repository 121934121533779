import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

import { CritereService } from 'app/shared/services/critere.service';
import { Observable } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpResponse } from '@angular/common/http';
import { OptionCheckbox } from 'app/shared/model/option-checkbox.js';
import { MatCheckboxChange } from '@angular/material/checkbox';

type EntityArrayResponseTypeOptionSelected = HttpResponse<OptionCheckbox[]>;

@Component({
  selector: 'app-querybuilders-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListComponent implements OnChanges {
  @Input() disabled;
  @Input() multiple: boolean;
  @Input() list: string;
  @Input() val: string;
  @Input() ngModel: string[];
  @Input() cible: any;
  @Input() rule: any;
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  data: any;
  isLoading = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  fields: any;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  optionCtrl = new FormControl();
  filteredOptions: Observable<EntityArrayResponseTypeOptionSelected>;

  @ViewChild('optionInput') optionInput: ElementRef;

  constructor(private critereService: CritereService) {
        
    this.critereService.querybuilderlist(this.list, '', this.cible, this.rule?.field);
    this.filteredOptions = this.optionCtrl.valueChanges.pipe(
      debounceTime(300),
      startWith(''),
      // tap(() => this.isLoading = true),

      switchMap(value => this.critereService.querybuilderlist(this.list, value, this.cible, this.rule?.field))
    );
    
  }

  ngOnChanges(): void {
    this.filteredOptions = this.optionCtrl.valueChanges.pipe(
      debounceTime(300),
      startWith(''),
      // tap(() => this.isLoading = true),
      switchMap(value => this.critereService.querybuilderlist(this.list, value, this.cible, this.rule?.field))
    );
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value.trim() + ':' + event.value.trim();

    if (!this.ngModel.includes(value)) {
      if (value !== ':' && (value || '').trim()) {
        this.ngModel.push(value);
      }
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.optionCtrl.setValue(null);
  }

  remove(option: string): void {
    const index = this.ngModel.indexOf(option);

    if (index >= 0) {
      this.ngModel.splice(index, 1);
      this.ngModelChange.emit(this.ngModel);
    }
  }

  selected(event: MatCheckboxChange, val: OptionCheckbox): void {
    this.ngModel = this.ngModel || [];

    const value = val.value + ':' + val.name;

    if (event.checked) {
      if (!this.ngModel.includes(value)) {
        this.ngModel.push(value);
        this.ngModelChange.emit(this.ngModel);
      }
    } else {
      const index = this.ngModel.indexOf(value);

      if (index >= 0) {
        this.ngModel.splice(index, 1);
        this.ngModelChange.emit(this.ngModel);
      }
    }

    // this.optionInput.nativeElement.value = '';
    // this.optionInput.nativeElement.blur();
    // this.optionCtrl.setValue(null);
  }

  updatelabel(type: string): string {
    const pattern = /^.*_\d{1,19}$/;
    if (this.list && this.list === 'ListeAffaire_import') {
      let value = type.substring(0, type.indexOf(':'));
      if (value && !pattern.test(value)) return value;
    }
    return type.substring(type.indexOf(':') + 1);
  }

  updatevalue(): void {
    this.ngModelChange.emit(this.ngModel);
  }

  isChecked(val1: OptionCheckbox): boolean {
    return this.ngModel.some(x => x.toLowerCase().substring(x.indexOf(':') + 1) == val1.value.toLowerCase());
  }

  onClosedEvent(event) {
    this.optionInput.nativeElement.value = '';
    this.optionInput.nativeElement.blur();
    this.optionCtrl.setValue(null);
  }
}
