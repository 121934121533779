import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';

import { ProfileService } from '../../profile.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UtilisateurService } from 'app/shared/services/utilisateur.service';
import { AuthorizationService } from 'app/shared/authorization/authorization.service';
import { UserInfo } from 'angular-oauth2-oidc';
import { IUtilisateur } from 'app/shared/model/utilisateur.model';

@Component({
  selector: 'profile-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ProfileTimelineComponent implements OnInit, OnDestroy {
  timeline: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  dataProfil: IUtilisateur;
  currentUser: UserInfo;
  ipn: any;
  defaultAffaire: string;
  code: string;
  isswitcheduser: boolean = true;

  /**
   * Constructor
   *
   * @param _profileService
   */
  constructor(
    private _profileService: ProfileService,
    private apiservice: UtilisateurService,
    private authorizationService: AuthorizationService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._profileService.timelineOnChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(timeline => {
      this.timeline = timeline;
    });
    this.authorizationService.currentUser.subscribe(x => {
      this.currentUser = x;
      this.ipn = this.currentUser.uid;
    });
    this.authorizationService.isswitcheduserflag.subscribe(x => {
      this.isswitcheduser = x;
      this.getProfil();
    });
  }

  getProfil() {
    this.apiservice.createUserProfil().subscribe((res: any) => {
      this.dataProfil = res.body.affairepardefaut;
      this.code = res.body.affairepardefaut.code;
      this.defaultAffaire = res.body.affairepardefaut.libelle;
    });
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
