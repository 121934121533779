import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/shared/services/authentication.service';
import { Role } from 'app/shared/model/role.model';
import { AuthorizationService } from 'app/shared/authorization/authorization.service';
import { UserInfo } from 'angular-oauth2-oidc';
import { environment } from 'environments/environment';
import { UtilisateurService } from 'app/shared/services/utilisateur.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatDialog } from '@angular/material/dialog';
import { UserswitchComponent } from './userswitch/userswitch.component';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @Output() profilutilisateur: EventEmitter<any> = new EventEmitter();

  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  logged;
  public isAdmin = false;
  myadmin = false;
  loading = false;
  currentUser: UserInfo;
  urlimage: string;
  data: any;
  isswitcheduserflag = new BehaviorSubject<boolean>(false);
  profileclass: any = 'switcher-image-off';
  // Private
  private _unsubscribeAll: Subject<any>;

  ipn: any;
  dataProfil: any;
  defaultAffaire: string;
  dataProfilUser: any;

  isswitcheduser: boolean;
  nom: any;
  prenom: any;
  isaffaire: boolean;
  /**
   * Constructor
   *
   * @param _fuseConfigService
   * @param _fuseSidebarService
   * @param _translateService
   */
  constructor(
    private authorizationService: AuthorizationService,
    private apiservice: UtilisateurService,
    private authentService: AuthenticationService,
    public dialog: MatDialog,
    private router: Router,
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService
  ) {
    this.profilutilisateur = new EventEmitter<any>();

    this.urlimage = environment.apiImg;
    // Set the defaults
    this.userStatusOptions = [
      {
        title: 'Online',
        icon: 'icon-checkbox-marked-circle',
        color: '#4CAF50',
      },
      {
        title: 'Away',
        icon: 'icon-clock',
        color: '#FFC107',
      },
      {
        title: 'Do not Disturb',
        icon: 'icon-minus-circle',
        color: '#F44336',
      },
      {
        title: 'Invisible',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#BDBDBD',
      },
      {
        title: 'Offline',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#616161',
      },
    ];

    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us',
      },
      {
        id: 'fr',
        title: 'French',
        flag: 'tr',
      },
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  userRoles: Role[];

  logout() {
    this.router.navigate(['logout']);
  }
  public is_Admin() {
    //   console.log('is admin : ' + this.authentService.hasRole(Role.Admin));
    //   this.isAdmin = this.authentService.hasRole(Role.Admin);
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */

  ngOnInit(): void {
    this.authorizationService.currentUser.subscribe(x => {
      (this.currentUser = x), (this.ipn = this.currentUser.uid);
    });

    this.authorizationService.currentUser.subscribe(x => (this.currentUser = x));
    this.authorizationService.isswitcheduserflag.subscribe(x => {
      this.isswitcheduser = x;
      this.getProfil();
    });

    // Subscribe to the config changes
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(settings => {
      this.horizontalNavbar = settings.layout.navbar.position === 'top';
      this.rightNavbar = settings.layout.navbar.position === 'right';
      this.hiddenNavbar = settings.layout.navbar.hidden === true;
    });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });

    this.apiservice.query().subscribe(res => {
      this.data = res.body;
    });

    this.getProfil();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
    console.log(value);
  }

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }

  getProfil() {
    this.apiservice.createUserProfil().subscribe((res: any) => {
      this.dataProfilUser = res.body;
      this.defaultAffaire = res.body.affairepardefaut.libelle;
      this.nom = this.dataProfilUser.utilisateurDto.nom;
      this.prenom = this.dataProfilUser.utilisateurDto.prenom;

      this.isaffaire = this.isswitcheduser ? false : (res.body.serviceDto.groupecode == 'RZO' ? true : false);

      this.profileclass = this.isswitcheduser
        ? res.body.serviceDto.groupecode == 'RZO'
          ? 'switcher-image-on-aff'
          : 'switcher-image-on'
        : 'switcher-image-off';
    });
  }

  Gotoprofile() {
    this.router.navigate(['pages/profile']);
  }
  deconnect() {
    this.router.navigate(['pages/auth/login']);
  }

  ngAfterViewInit() {
    this.profilutilisateur.next(this.dataProfilUser);
  }
  onImgError(event) {
    event.target.src = 'assets/images/avatars/profile.jpg';
  }

  switcheruser: boolean = true;

  userChanges($event: MatSlideToggleChange) {
    if ((this.isswitcheduser = $event.checked)) {
      const dialogRef = this.dialog.open(UserswitchComponent, {
        width: '50%',
        data: this.data,
      });
      dialogRef.afterClosed().subscribe(result => {
        if (!result) {
          this.isswitcheduser = !this.isswitcheduser;
        }
      });
    } else {
      this.authorizationService.switchoff(true, true);
    }
  }
}
