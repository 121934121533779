import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilisateurService } from './utilisateur.service';
import { User } from 'app/shared/model/user.model';
import { Role } from 'app/shared/model/role.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private user: any;
  isswitcheduser;
  constructor(private http: HttpClient, private userService: UtilisateurService) {
    //     this.authorizationService.isswitcheduserflag.subscribe(x=> {
    //         this.isswitcheduser =x;
    //     });
    //     if(this.isswitcheduser){

    //       this.currentUserSubject = new BehaviorSubject<User>(
    //         JSON.parse(sessionStorage.getItem("switcheruser"))
    //       );
    //         // alert("true ... "+"storage : "+iswitcher);

    //     }else{
    //       this.currentUserSubject = new BehaviorSubject<User>(
    //         JSON.parse(sessionStorage.getItem("currentUser"))
    //       );
    // //         // alert("false ... "+"storage : "+iswitcher);

    //     }

    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  async login(): Promise<void> {
    this.userService
      .getCurrentUser()
      .toPromise()
      .then(response => {
        if (response !== undefined) {
          this.user = response as User;
          sessionStorage.setItem('currentUser', JSON.stringify(this.user));
          this.currentUserSubject.next(this.user);
        }
      });
    await new Promise<void>(() => {});
  }

  logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  isloggedin() {
    if (sessionStorage.getItem('user')) {
      return true;
    } else {
      return false;
    }
  }

  isAuthorized() {
    // return !!this.user;
    return this.isloggedin();
  }
  hasRole(role: Role) {
    const usr = sessionStorage.getItem('user');
    const currentuser = JSON.parse(usr);
    const curent_role = currentuser.role;

    return this.isAuthorized() && curent_role === role;
  }

  login2(data: any) {
    return this.http.post('', data);
  }
  setcurrentuser(data: any) {
    this.currentUserSubject.next(data);
    this.currentUser = data;
  }
  storeuser(data: any) {
    return;
  }
}
