<div id="about" class="p-24" fxLayout="row wrap">
  <div class="about-content" class="row space" fxLayout="row" fxLayoutGap="20px" fusePerfectScrollbar fxFlex.gt-sm="50" fxFlex.gt-md="100">
    <div fxFlex="50">
      <div class="profile-box info-box general" fxLayout="column">
        <div class="accent">
          <div class="title">Informations générales</div>
        </div>

        <div class="content">
          <div class="info-line">
            <div class="title">Civilité :</div>
            <div class="info"></div>
          </div>

          <div class="info-line">
            <div class="title">Nom :</div>
            <div class="info">{{ nomuser }}</div>
          </div>

          <div class="info-line">
            <div class="title">Prénom :</div>
            <div class="info location" fxLayout="row" fxLayoutAlign="start center">
              {{ prenomuser }}
            </div>
          </div>

          <div class="info-line">
            <div class="title">Fonction :</div>
            <div class="info"></div>
          </div>

          <div class="info-line">
            <div class="title">IPN :</div>
            <div class="info">{{ ipn }}</div>
          </div>
        </div>
      </div>
    </div>

    <div fxFlex="50">
      <div class="profile-box info-box general" fxLayout="column">
        <div class="accent">
          <div class="title">Coordonnées pour correspondance</div>
        </div>

        <div class="content">
          <div class="info-line">
            <div class="title">Adresse mail :</div>
            <div class="info">{{ ref_mail }}</div>
          </div>

          <div class="info-line">
            <div class="title">Tél fixe :</div>
            <div class="info">{{ ref_telephone }}</div>
          </div>

          <div class="info-line">
            <div class="title">Tél portable :</div>
            <div class="info location" fxLayout="row" fxLayoutAlign="start center">
              {{ ref_portable }}
            </div>
          </div>

          <div class="info-line">
            <div class="title">Adresse postale :</div>
            <div class="info">{{ ref_adresse }}</div>
          </div>

          <div class="actions" fxLayout="row" fxLayoutAlign="end center" [@animate]="{ value: '*', params: { delay: '200ms' } }">
            <button mat-raised-button color="accent" aria-label="Follow" (click)="updateCoordonneUser()">Modifier</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
