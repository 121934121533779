import { NgModule } from '@angular/core';
import { AuthorizationService } from './authorization.service';
import { AuthGuard } from './authguard/authguard.service';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LogoutComponent } from './logout/logout.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { OAuthModule } from 'angular-oauth2-oidc';

@NgModule({
  imports: [RouterModule, CommonModule, MatProgressBarModule, OAuthModule.forRoot()],
  declarations: [LogoutComponent],
  providers: [AuthGuard, AuthorizationService],
  exports: [RouterModule],
})
export class AuthorizationModule {
  public static forRoot() {
    return {
      ngModule: AuthorizationModule,
      providers: [AuthorizationService],
    };
  }
}
