export class QuickPanelFakeDb {
  public static notes = [
    {
      title: 'Rappel personnalisation PLV',
      detail: 'Les notifications envoyées par \n des utilisateurs centraux ',
    },
  ];

  public static events = [
    {
      title: 'OPO',
      detail: 'les prochaines campagnes',
    },
  ];
}
