import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class HeaderService {
  private texte = new BehaviorSubject<string>('');

  getText(): Observable<string> {
    return this.texte.asObservable();
  }

  setText(text): void {
    this.texte.next(text);
  }
}
