<div class="nav" [ngClass]="{ horizontal: layout === 'horizontal', vertical: layout === 'vertical' }">
  <!-- Vertical Navigation Layout -->
  <ng-container *ngIf="layout === 'vertical'">
    <ng-container *ngFor="let item of navigation">
      <fuse-nav-vertical-group *ngIf="item.type == 'group'" [item]="item"></fuse-nav-vertical-group>
      <fuse-nav-vertical-collapsable *ngIf="item.type == 'collapsable'" [item]="item"></fuse-nav-vertical-collapsable>
      <fuse-nav-vertical-item *ngIf="item.type == 'item'" [item]="item"></fuse-nav-vertical-item>
    </ng-container>
  </ng-container>
  <!-- / Vertical Navigation Layout -->

  <!-- Horizontal Navigation Layout -->
  <ng-container *ngIf="layout === 'horizontal'">
    <ng-container *ngFor="let item of dataMenu">
      <fuse-nav-horizontal-collapsable *ngIf="item.type == 'group'" [item]="item"></fuse-nav-horizontal-collapsable>
      <fuse-nav-horizontal-collapsable *ngIf="item.type == 'collapsable'" [item]="item"></fuse-nav-horizontal-collapsable>
      <fuse-nav-horizontal-item *ngIf="item.type == 'item'" [item]="item"></fuse-nav-horizontal-item>
    </ng-container>
  </ng-container>
  <!-- / Horizontal Navigation Layout -->
</div>
