import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { IMultiAffaireVolume } from '../model/multiAffaireVolume.model';

type EntityResponseType = HttpResponse<IMultiAffaireVolume>;

@Injectable({
  providedIn: 'root',
})
export class MultiAffaireVolumeService {
  public resourceUrl = environment.apiURL + '/affaireVolumes';

  constructor(protected http: HttpClient) {}

  getAffaireVolumes(code: string): Observable<EntityResponseType> {
    return this.http.get<IMultiAffaireVolume>(`${this.resourceUrl}` + '/' + code, { observe: 'response' });
  }
}
