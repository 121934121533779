<div class="bgError">
  <div class="number">{{ errorNumber }}</div>
  <div id="error">
    <div id="alert">
      <h1 style="color: #fff"><img src="assets/img/logo_renault.png" width="100" alt="" /><br />Renault</h1>
      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
      <!-- Error GEn -->

      <h3>{{ rnltErrorMessage.titre }}</h3>
      <p>{{ rnltErrorMessage.message }}</p>

      <a (click)="redirectAfterError()" class="btLink">{{ rnltErrorMessage.buttonLabel }}</a>
    </div>
  </div>
</div>
